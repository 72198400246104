import { useEffect } from "react";

import { Box, Container, Button, Paper } from "@mui/material";
import gql from "graphql-tag";
import { useParams } from "react-router";
import { useMutation } from "@apollo/client";

const AGREEMENT = gql`
	mutation partnersAgreement($email: String!) {
		partnersAgreement(email: $email)
	}
`;

/**
 * Page to be displayed after clicking on link in e-mail.
 * User needs to confirm his/hers agreement, with terms of dance studio, so he can be signed in to ball dance.
 * Takes e-mail from parameters and sends it to backend.
 * @returns {Component} Paper with Container and information.
 */
export function PartnerAgreement() {
	const { email } = useParams();

	const [agreementRequest, { loading, data, error }] = useMutation(
		AGREEMENT,
		{
			onCompleted: (data) => {
				console.log(data.setSubscription);
			},
			onError: (error) => {
				console.log(error.message);
			},
		}
	);

	useEffect(() => {
		agreementRequest({ variables: { email: email} });
	}, [email, agreementRequest]);

	return (
		<Paper
			elevation={24}
			sx={{
				width: "100%",
				maxWidth: "600px",
				p: "50px",
				px: "10px",
				borderRadius: "20px",
				alignSelf: "center",
				mx: "auto",
				bgcolor: "Snow",
				my: "80px",
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Container
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{loading && (
						<div>
							<h1>Ověřování...</h1>
						</div>
					)}

					{error && (
						<div>
							<h1>Žádost se nepodařilo přijmout</h1>
							<p>{error.message}</p>
							<Button
								variant="contained"
								size="large"
								color="primary"
								sx={{ mt: "1%" }}
								href="/"
							>
								Zpět na hlavní stránku
							</Button>
						</div>
					)}

					{data && (
						<div>
							<h1>Souhlas s podmínkami účasti a provozním řádem přijat.</h1>
							<p>Děkujeme za Váš zájem a budeme se těšit na první lekci.</p>
							<Button
								variant="contained"
								size="large"
								color="primary"
								sx={{ mt: "1%" }}
								href="/"
							>
								Zpět na hlavní stránku
							</Button>
						</div>
					)}
				</Container>
			</Box>
		</Paper>
	);
}
