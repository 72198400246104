import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useAuth } from "src/utils/auth";
import { AdminPanel } from "src/templates";
import { SnackBarHandler } from "src/atoms";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
	ReservationDatePicker,
	UsersSeasonEventsTable,
} from "src/molecules";
import { AlternativeDateDialog } from "../Dialogs/AlternativeDateDialog";

const EVENTS_LIST = gql`
	query usersReservationsBySeasonId($user_id: Int!, $season_id: Int!) {
		usersReservationsBySeasonId(user_id: $user_id, season_id: $season_id) {
			capacity
			reservation_id
			user_id
			event_id
			event_start
			event_end
			event_date
			duration
			min_participation
			reservation_count
			comment
			attended
			season_id
			signed_out
			paid
			held
			women_count
			men_count
			pairs_count
			isIn7Hours
		}
	}
`;

const SEASON_INFORMATION = gql`
	query season_info($season_id: Int!) {
		ballroomSeasonById(season_id: $season_id) {
			season
			day
			start_time
			end_time
		}
	}
`;

const EVENT_DAYS = gql`
	query days(
		$date: String!
		$course_id: Int!
		$user_id: Int!
		$season_id: Int!
	) {
		daysOfEventsInMonthBySeasonIdAndUserId(
			date: $date
			course_id: $course_id
			season_id: $season_id
			user_id: $user_id
		) {
			event_days
		}
	}
`;

const SIGNOUTS_NUMBER = gql`
	query signoutsNumber($user_id: Int!, $season_id: Int!) {
		numberOfUsersFreeEntries(user_id: $user_id, season_id: $season_id)
	}
`;

const USER = gql`
	query userById($user_id: Int!) {
		userById(user_id: $user_id){
			gender
		}
	}
`;

const DAY_MAP = {
	monday: "pondělí",
	tuesday: "úterý",
	wednesday: "středa",
	thursday: "čtvrtek",
	friday: "pátek",
	saturday: "sobota",
	sunday: "neděle",
};

/**
 * Profile section of selected registered ball dance season.
 * User can excuse him/herself from an event, and ask for substitude one, which opens a dialog.
 * Also he can see capacity, comments and other information about the events.
 * @returns {Component} Table with all seasons events and Calendar
 */
export function MyBallroomCourse() {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [user_id] = useState(decoded?.user_id);
	const { season_id, course_id } = useParams();
	const tablet = useMediaQuery("(max-width:1560px)");

	const [dateValue, setDateValue] = useState(new Date());
	const [month, setMonthDate] = useState(new Date());

	const [alternativeDialogOpen, setAlternativeDialogOpen] = useState(false);

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const seasonInfoQuery = useQuery(
		SEASON_INFORMATION,
		{
			variables: {
				season_id: parseInt(season_id),
			},
		},
		[]
	);

	const userQuery = useQuery(
		USER,
		{
			variables: {
				user_id: parseInt(user_id),
			},
		},
		[]
	);

	const signoutsNumber = useQuery(
		SIGNOUTS_NUMBER,
		{
			variables: {
				season_id: parseInt(season_id),
				user_id: parseInt(user_id),
			},
		},
		[]
	);

	const getDaysOfEvents = useQuery(EVENT_DAYS, {
		variables: {
			course_id: parseInt(course_id),
			user_id: parseInt(user_id),
			season_id: parseInt(season_id),
			date: `${month.getFullYear()}-${
				month.getMonth() + 1
			}-${month.getDate()}`,
		},
	});

	const eventsQuery = useQuery(EVENTS_LIST, {
		variables: {
			user_id: parseInt(user_id),
			season_id: parseInt(season_id),
		},
	});

	const refetch = function () {
		eventsQuery.refetch();
		signoutsNumber.refetch();
	};

	return (
		<AdminPanel>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					justifyContent: "space-evenly",
					flexDirection: "column",
				}}
			>
				{seasonInfoQuery.data && (
					<Typography
						variant="h2"
						color="initial"
						fontSize="1.5em"
						marginBottom="20px"
					>
						{`${seasonInfoQuery.data.ballroomSeasonById.season}, 
						${DAY_MAP[seasonInfoQuery.data.ballroomSeasonById.day]} 
						${seasonInfoQuery.data.ballroomSeasonById.start_time}–${
							seasonInfoQuery.data.ballroomSeasonById.end_time
						}`}
					</Typography>
				)}
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Box sx={{display: tablet ? "none" : "box"}}>
							<ReservationDatePicker
								dateValue={dateValue}
								setDateValue={setDateValue}
								month={month}
								setMonthDate={setMonthDate}
								getDaysOfEvents={getDaysOfEvents}
								queryName="daysOfEventsInMonthBySeasonIdAndUserId"
							/>
						</Box>

						<Box sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
							<Grid container sx={{
									width: '100%',
									mb: "5px",
									
								}}>
								{signoutsNumber.data && (
									<>
										<Grid item xs={12} lg={6}>
											<Typography
												variant="body1"
												color="initial"
											>
												{"Volných vstupů: "}
												{
													signoutsNumber.data
														.numberOfUsersFreeEntries
												}
											</Typography>
										</Grid>
										<Grid item xs={12} lg={6} sx={{display: 'flex'}}>
											{signoutsNumber.data
												.numberOfUsersFreeEntries >
												0 && (
												<Button
													sx={{ml: 'auto'}}
													onClick={() => {
														setAlternativeDialogOpen(
															true
														);
													}}
													variant="contained"
												>
													Požádat o náhradní termín
												</Button>
											)}
										</Grid>
									</>
								)}
							</Grid>
							<UsersSeasonEventsTable
								query={eventsQuery.data && eventsQuery.data.usersReservationsBySeasonId}
								setSnackMsg={setSnackMsg}
								setSnackOpen={setSnackOpen}
								setSnackState={setSnackState}
								refetch={refetch}
								signOutsNumber={signoutsNumber.data}
								freeEntriesQueryName="numberOfUsersFreeEntries"
								queryName="usersReservationsBySeasonId"
								capacityCounterName={userQuery.data && (userQuery.data.gender === "male" ? "men_count" : userQuery.data.gender === "female" ? "women_count" : "pairs_count")}
							/>
						</Box>
					</Box>
				</Box>
				<SnackBarHandler
					snackState={snackState}
					snackMsg={snackMsg}
					snackOpen={snackOpen}
					setSnackOpen={setSnackOpen}
				/>
				<AlternativeDateDialog
					setOpen={setAlternativeDialogOpen}
					open={alternativeDialogOpen}
					title="Požádat o náhradní termín"
					season_id={season_id}
					user_id={user_id}
					setSnackMsg={setSnackMsg}
					setSnackOpen={setSnackOpen}
					setSnackState={setSnackState}
				/>
			</Box>
		</AdminPanel>
	);
}
