import PropTypes from "prop-types"
import { gql, useMutation } from "@apollo/client";
import { Upload } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";

import { FormikField } from "../../molecules/FormikField";

const NEW_PHOTO = gql`
	mutation newPhoto($name: String!, $photo: String!, $album_id: Int!) {
		newPhoto(name: $name, photo: $photo, album_id: $album_id)
	}
`;

/**
 * Dialog to add new photos to album
 * @param {Boolean} open open/close dialog state
 * @param {Function} setOpen open/close dialog
 * @param {Function} refetch refetch new data from database
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} album_id album id
 * @returns {Component} Dialog
 */
export function AddPhotosDialog({
	open,
	setOpen,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
	refetch,
	album_id
}) {
	let [filesBase64, setfilesBase64] = useState("");
	const [files, setFile] = useState([]);

	const [addNewPhotoRequest] = useMutation(NEW_PHOTO, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.newPhoto);
			setSnackOpen(true);
			handleClose();
			setfilesBase64("");
			setFile([]);
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleAddNewPhoto = useCallback(
		(values) => {
			addNewPhotoRequest({
				variables: {
					name: values.name,
					album_id: parseInt(album_id),
					photo: filesBase64
				},
			});
		},
		[addNewPhotoRequest, album_id, filesBase64]
	);

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	const handleChange = (e) => {
		let allfiles = [];

		for (let i = 0; i < e.target.files.length; i++) {
			if (e.target.files[i].size > 5e5) {
				setSnackState("error");
				setSnackMsg(
					"Prosím nahrajte obrázek s velikostí menší než 0,5 MB."
				);
				setSnackOpen(true);
				return false;
			}
			allfiles.push(e.target.files[i]);
		}
		setFile(e.target.files);
		getBase64(e.target.files[0]);
	};

	const getBase64 = async (file) => {
		
		return new Promise((resolve) => {
			let baseURL = "";
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				baseURL = reader.result;
				setfilesBase64(baseURL);
				resolve(baseURL);
			};
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth={true}
		>
			<Formik
				onSubmit={handleAddNewPhoto}
				initialValues={{
					name: "",
					photo: "",
				}}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<DialogTitle id="alert-dialog-title">
						{"Přidat fotografii"}
					</DialogTitle>
					<DialogContent>
						<FormikField
							id="name"
							name="name"
							label="Název"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
							sx={{ mt: "10px" }}
						/>
						<Button
							variant="contained"
							sx={{
								borderRadius: "5px",
								mx: "5px",
								my: '10px',
								p: '10px'
							}}
							size="small"
							startIcon={<Upload />}
							color="secondary"
						>
							<label
								style={{
									width: "100%",
									height: "100%",
									cursor: "pointer",
									alignItems: "center",
									display: "flex",
									
								}}
								onChange={handleChange}
								htmlFor="editImg"
							>
								<input
									accept="image/*"
									id="editImg"
									type="file"
									hidden
								/>
								
								{files.length > 0 ? (
									<>{files[0].name} </>
								) : (
									<> Nahrát fotografii</>
								)}
							</label>
						</Button>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Uložit
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

AddPhotosDialog.propTypes = {
	album_id: PropTypes.any,
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
