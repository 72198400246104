import {
	IconButton,
	ImageList,
	ImageListItem,
	InputBase,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useCallback, useState } from "react";
import { AdminPanel } from "src/templates";
import {
	Add,
	ArrowForwardIos,
	Check,
	Close,
	Delete,
	Edit,
} from "@mui/icons-material";
import gql from "graphql-tag";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ModalImage from "react-modal-image";
import { useEffect } from "react";
import { NewAlbumDialog } from "../Dialogs/NewAlbumDialog";
import { DeleteDialog } from "../Dialogs/DeleteDialog";
import { AddPhotosDialog } from "../Dialogs/AddPhotosDialog";

const ALBUMS_LIST = gql`
	query albums {
		albums {
			album_id
			name
			created_at
		}
	}
`;

const SELECTED_ALBUM = gql`
	query albumById($album_id: Int!) {
		albumById(album_id: $album_id) {
			album_id
			name
			created_at
		}
	}
`;

const SELECTED_ALBUMS_PHOTOS = gql`
	query albumsPhotos($album_id: Int!) {
		albumsPhotos(album_id: $album_id) {
			photo_id
			photo
			name
		}
	}
`;

const DELETE_ALBUM = gql`
	mutation deleteAlbum($album_id: Int!) {
		deleteAlbum(album_id: $album_id)
	}
`;
const DELETE_PHOTO = gql`
	mutation deletePhoto($photo_id: Int!) {
		deletePhoto(photo_id: $photo_id)
	}
`;

const CHANGE_ALBUM_NAME = gql`
	mutation changeAlbumName($album_id: Int!, $name: String!) {
		changeAlbumName(album_id: $album_id, name: $name)
	}
`;
/**
 * Admin section to create, edit or delete albums and photos.
 * Consists of 3 parts: Button to Create new album, 
 * List of all albums to select and editable detail of an album with photos to add or delete.
 * @return {Component} Button, List and Preview
 */
export function PhotoManagement() {
	const formRef = useRef(null);

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [openNewAlbumDialog, setOpenNewAlbumDialog] = useState(false);
	const [openAddPhotosDialog, setOpenAddPhotosDialog] = useState(false);
	const [editAlbumName, setEditAlbumName] = useState(false);
	const [openDeleteAlbumDialog, setOpenDeleteDialog] = useState(false);
	const [newAlbumName, setNewAlbumName] = useState("");
	const [delete_id, setDeleteId] = useState("");
	const [deleteFunction, setDeleteFunction] = useState("");

	const AlbumsListQuery = useQuery(ALBUMS_LIST);

	const [selectedAlbum, setSelectedAlbum] = useState();

	const [selectedAlbumQuery, { data: albumData, refetch: albumInfoRefetch }] =
		useLazyQuery(SELECTED_ALBUM);
	const [
		selectedAlbumsPhotosQuery,
		{ data: photosData, refetch: photosRefetch },
	] = useLazyQuery(SELECTED_ALBUMS_PHOTOS);

	useEffect(() => {
		if (albumData) {
			setNewAlbumName(albumData.albumById.name);
		}
	}, [albumData]);

	const [deleteAlbumQuery] = useMutation(DELETE_ALBUM, {
		onCompleted: (data) => {
			setSnackMsg(data.deleteAlbum);
			setSnackState("success");
			setSnackOpen(true);
			setSelectedAlbum("");
			AlbumsListQuery.refetch();
			window.location.reload();
		},
		onError: (error) => {
			setSnackMsg(error.msg);
			setSnackState("error");
			setSnackOpen(true);
		},
	});

	const [deletePhotoRequest] = useMutation(DELETE_PHOTO, {
		onCompleted: (data) => {
			setSnackMsg(data.deletePhoto);
			setSnackState("success");
			setSnackOpen(true);
			photosRefetch();
		},
		onError: (error) => {
			setSnackMsg(error.msg);
			setSnackState("error");
			setSnackOpen(true);
		},
	});

	const [changeAlbumNameRequest] = useMutation(CHANGE_ALBUM_NAME, {
		onCompleted: (data) => {
			setSnackMsg(data.changeAlbumName);
			setSnackState("success");
			setSnackOpen(true);
			setSelectedAlbum("");
			AlbumsListQuery.refetch();
			albumInfoRefetch();
		},
		onError: (error) => {
			setSnackMsg(error.msg);
			setSnackState("error");
			setSnackOpen(true);
		},
	});

	const handleAlbumPick = useCallback(
		async (album_id, formRef) => {
			setSelectedAlbum(album_id);
			setEditAlbumName(false);
			await selectedAlbumQuery({
				variables: {
					album_id: album_id,
				},
			});
			await selectedAlbumsPhotosQuery({
				variables: {
					album_id: album_id,
				},
			});
		},
		[selectedAlbumQuery, selectedAlbumsPhotosQuery]
	);

	const handleAlbumNameChange = useCallback(
		(event) => {
			event.preventDefault();
			changeAlbumNameRequest({
				variables: {
					album_id: selectedAlbum,
					name: event.target["name"].value,
				},
			});
			event.target.reset();
		},
		[changeAlbumNameRequest, selectedAlbum]
	);

	const deleteAlbum = useCallback(
		(album_id) => {
			deleteAlbumQuery({
				variables: {
					album_id,
				},
			});
		},
		[deleteAlbumQuery]
	);

	const deletePhoto = useCallback(
		(photo_id) => {
			deletePhotoRequest({
				variables: {
					photo_id,
				},
			});
		},
		[deletePhotoRequest]
	);

	return (
		<AdminPanel
			title="Galerie"
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						width: "40%",
					}}
				>
					<Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography variant="h5" color="initial">
								Alba
							</Typography>
							<IconButton
								onClick={() => {
									setOpenNewAlbumDialog(true);
								}}
								color="secondary"
								size="medium"
							>
								<Add />
							</IconButton>
						</Box>

						<Stack
							spacing={2}
							sx={{
								ml: "10px",
								overflow: "hidden",
								overflowY: "scroll",
								maxHeight: "500px",
								p: "5px",
							}}
						>
							{AlbumsListQuery.data &&
								AlbumsListQuery.data.albums.map(
									(album, index) => (
										<Paper
											key={index}
											elevation={4}
											sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												bgcolor:
													selectedAlbum ===
														album.album_id &&
													"primary.main",
											}}
										>
											<Typography
												variant="subtitle1"
												color="initial"
												sx={{
													ml: "10px",
													minWidth: "90px",
												}}
											>
												{album.created_at}
											</Typography>
											<Typography
												variant="body1"
												color="initial"
												sx={{ ml: "10px" }}
											>
												{album.name}
											</Typography>
											<IconButton
												sx={{ ml: "auto" }}
												onClick={() => {
													handleAlbumPick(
														album.album_id,
														formRef
													);
												}}
											>
												<ArrowForwardIos />
											</IconButton>
										</Paper>
									)
								)}
						</Stack>
					</Box>
				</Box>
				<Box sx={{ width: "60%", px: "20px" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						{albumData && (
							<>
								<Box
									noValidate
									autoComplete="off"
									id="changeNameForm"
									onSubmit={handleAlbumNameChange}
									component="form"
									sx={{
										textAlign: "left",
										mr: "auto",
										display: "flex",
										alignItems: "center",
									}}
									ref={formRef}
								>
									<Typography
										variant="h5"
										color="initial"
										sx={{
											display: editAlbumName
												? "none"
												: "flex",
										}}
									>
										{albumData.albumById.name}
									</Typography>
									<InputBase
										variant="outlined"
										color="secondary"
										value={newAlbumName}
										onChange={(e) => {
											setNewAlbumName(e.target.value);
										}}
										name="name"
										id="album_name"
										label="Název alba"
										disabled={!editAlbumName}
										sx={{
											border: "1px black solid",
											borderRadius: "5px",
											px: "5px",
											display: editAlbumName
												? "flex"
												: "none",
										}}
									/>
									<IconButton
										type="reset"
										size="medium"
										onClick={() => {
											setEditAlbumName(true);
										}}
										color="secondary"
										sx={{
											display: editAlbumName
												? "none"
												: "flex",
										}}
									>
										<Edit />
									</IconButton>
									<IconButton
										size="medium"
										onClick={(e) => {
											setEditAlbumName(false);
										}}
										color="success"
										sx={{
											display: editAlbumName
												? "flex"
												: "none",
										}}
										type="submit"
									>
										<Check />
									</IconButton>
									<IconButton
										size="medium"
										onClick={(e) => {
											setEditAlbumName(false);
										}}
										color="error"
										sx={{
											display: editAlbumName
												? "flex"
												: "none",
										}}
									>
										<Close />
									</IconButton>
								</Box>

								<IconButton
									onClick={() => {
										setDeleteId(
											albumData.albumById.album_id
										);
										setDeleteFunction(() => () => {
											deleteAlbum(selectedAlbum);
										});
										setOpenDeleteDialog(true);
									}}
									color="error"
									size="medium"
								>
									<Delete />
								</IconButton>

								<IconButton
									size="medium"
									onClick={() => {
										setOpenAddPhotosDialog(true);
									}}
									color="secondary"
								>
									<Add />
								</IconButton>
							</>
						)}
					</Box>
					<ImageList
						sx={{
							width: "100%",
							mx: "auto",
							overflow: "hidden",
							overflowY: "scroll",
							maxHeight: "500px",
						}}
						variant="standard"
						cols={4}
						rowHeight={250}
						gap={1}
					>
						{photosData ? (
							photosData.albumsPhotos.map((item) => (
								<ImageListItem
									key={item.photo_id}
									cols={2}
									sx={{ position: "relative" }}
								>
									<IconButton
										aria-label=""
										onClick={() => {
											setDeleteId(item.photo_id);
											setDeleteFunction(() => () => {
												deletePhoto(item.photo_id);
											});
											setOpenDeleteDialog(true);
										}}
										sx={{
											position: "absolute",
											top: "0px",
											right: "0px",
										}}
									>
										<Delete color="error" />
									</IconButton>

									<ModalImage
										className="adminGalleryImage"
										small={item.photo}
										large={item.photo}
										alt={item.name}
										loading="lazy"
									/>
								</ImageListItem>
							))
						) : (
							<Box></Box>
						)}
					</ImageList>
				</Box>
			</Box>
			<NewAlbumDialog
				open={openNewAlbumDialog}
				setOpen={setOpenNewAlbumDialog}
				setSnackState={setSnackState}
				setSnackMsg={setSnackMsg}
				setSnackOpen={setSnackOpen}
				refetch={() => {
					AlbumsListQuery.refetch();
				}}
			/>
			<DeleteDialog
				open={openDeleteAlbumDialog}
				setOpen={setOpenDeleteDialog}
				id={delete_id}
				deleteFunction={deleteFunction}
			/>
			<AddPhotosDialog
				open={openAddPhotosDialog}
				setOpen={setOpenAddPhotosDialog}
				setSnackState={setSnackState}
				setSnackMsg={setSnackMsg}
				setSnackOpen={setSnackOpen}
				refetch={() => {
					AlbumsListQuery.refetch();
					selectedAlbumsPhotosQuery({
						variables: {
							album_id: selectedAlbum,
						},
					});
				}}
				album_id={selectedAlbum}
			/>
		</AdminPanel>
	);
}
