import PropTypes from "prop-types"
import {
	Avatar,
	Badge,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import * as yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { CameraAlt } from "@mui/icons-material";
import { FormikField } from "src/molecules";

const schema = yup.object().shape({
	name: yup.string().required("Název je povinný údaj"),
	height: yup.string().required("Výška je povinný údaj"),
	link: yup.string().required("Link je povinný údaj"),
	photo: yup.string().required("Logo je povinné"),
});

const ADD_SPONSOR = gql`
	mutation addSponsor(
		$name: String!
		$height: Int!
		$link: String
		$img: String!
	) {
		addSponsor(name: $name, height: $height, link: $link, img: $img)
	}
`;

/**
 * Dialog to add new sponsor with a form
 * @param {Boolean} open open/close dialog state
 * @param {Function} setOpen open/close dialog
 * @param {Function} refetch refetch new data from database
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @returns {Component} Dialog
 */
export function AddSponsorDialog({
	open,
	setOpen,
	refetch,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
}) {
	const [name] = useState("");
	const [height] = useState("");
	const [link] = useState("");
	const [photo, setPhoto] = useState("");
	let filesBase64 = [];

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	const [addSponsorRequest] = useMutation(ADD_SPONSOR, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.addSponsor);
			setSnackOpen(true);
      setPhoto("");
			handleClose();
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSubmit = useCallback(
		(values) => {
			addSponsorRequest({
				variables: {
					name: values.name,
					link: values.link,
					height: parseInt(values.height),
					img: photo,
				},
			});
		},
		[addSponsorRequest, photo]
	);

	const handleImageUpload = (e) => {
		let allfiles = [];

		for (let i = 0; i < e.target.files.length; i++) {
			if (e.target.files[i].size > 5e5) {
				setSnackState("error");
				setSnackMsg(
					"Prosím nahrajte obrázek s velikostí menší než 0,5 MB."
				);
				setSnackOpen(true);
				return false;
			}
			allfiles.push(e.target.files[i]);
		}

		getBase64(e.target.files[0]);
	};

	const getBase64 = async (file) => {
		return new Promise((resolve) => {
			let baseURL = "";
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				baseURL = reader.result;
				filesBase64.push(baseURL);
				resolve(baseURL);
			};
		}).then(async (res) => {
			setPhoto(res);
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth={true}
			transitionDuration={500}
		>
			<Formik
				onSubmit={handleSubmit}
				initialValues={{
					name: name,
					link: link,
					height: height,
					photo: photo,
				}}
				validationSchema={schema}
				validateOnBlur={true}
				enableReinitialize={true}
			>
				<Form>
					<Box sx={{ mx: "auto", width: "fit-content" }}>
						<Badge
							overlap="circular"
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							badgeContent={
								<IconButton
									disableFocusRipple={true}
									aria-label="Nahrát fotografii"
									sx={{
										color: "#242424",
										bgcolor: "#bdbdbd",
									}}
								>
									<label
										style={{
											width: "36px",
											height: "36px",
											cursor: "pointer",
										}}
										onChange={handleImageUpload}
										htmlFor="editImg"
									>
										<input
											accept="image/*"
											id="editImg"
											type="file"
											hidden
											style={{
												width: "0px",
												height: "0px",
											}}
										/>
										<CameraAlt fontSize="large" />
									</label>
								</IconButton>
							}
						>
							<Avatar
								sx={{
									mx: "auto",
									width: 164,
									height: 164,
									mt: "20px",
								}}
								src={photo}
							/>
						</Badge>
					</Box>
					<DialogContent>
						<FormikField
							id="name"
							type="text"
							variant="outlined"
							name="name"
							sx={{ my: "10px", mx: "20px" }}
							label="Název"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="height"
							type="text"
							variant="outlined"
							name="height"
							sx={{ my: "10px", mx: "20px" }}
							label="Výška obrázku [px]"
							as="TextField"
							color="secondary"
						/>
						<FormikField
							id="link"
							type="text"
							variant="outlined"
							name="link"
							sx={{ my: "10px", mx: "20px" }}
							label="Link"
							as="TextField"
							color="secondary"
						/>
					</DialogContent>

					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Uložit
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

AddSponsorDialog.propTypes = {
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
