import PropTypes from "prop-types"
import {
	Alert,
	Button,
	Container,
	Paper,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import { SnackBarHandler } from "src/atoms";
import { BuyCourseDialog } from "../Dialogs/BuyCourseDialog";
import { SuccessSignUpDialog } from "../Dialogs/SuccessSignUpDialog";

/**
 * Container to display dance studio courses on Courses page.
 * It displays button to open dialog where user can sign up for available seasons of the course.
 * The text displayed is part of editable content and in control of the admin.
 * @param {Number} id course id
 * @param {String} ref reference for smooth scroll after navigation
 * @param {String} description rich text description of course
 * @param {String} name name of the course
 * @param {String} courseId course id
 * @param {String} img base64 course image
 * @param {String} gender gender
 * @returns {Component} MUI Paper with dance studio course content 
 */
export function CourseContainer({ description, id, name, img, courseId, ref, gender }) {
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);
	const mobile = useMediaQuery("(max-width:1200px)");
	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	const [buyCourseDialogOpen, setBuyCourseDialogOpen] = useState(false);
	const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
	const [finalPrice, setFinalPrice] = useState("");

	return (
		<Box
			ref={ref}
			id={id}
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				bgcolor: "rgba(255, 255, 255, 0.75)",
				width: "95%",
				maxWidth: "1600px",
				mx: "auto",
				borderRadius: "15px",
				py: "20px",
				mb: "50px",
				scrollMarginTop: "-40px",
			}}
			component={Paper}
			elevation={16}
		>
			<Container
				maxWidth="xl"
				sx={{
					display: "flex",
					justifyContent: "space-between",
					my: "20px",
					flexDirection: mobile ? "column" : "row",
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="flex-start"
					px="10px"
					justifyContent="space-evenly"
				>
					<Typography
						variant="h3"
						color="initial"
						sx={{
							"&>*": {
								margin: "0px",
							},
						}}
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(name))
						)}
					></Typography>
					<div
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(description))
						)}
					></div>

					{id !== 3 || gender === "pair" ? (
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setBuyCourseDialogOpen(true);
								}}
							>
								Objednat hodiny
							</Button>
						</Box>
					) : null}
				</Box>

				<Box
					component="img"
					src={`/img/${img}`}
					className="hideOnSmall"
					sx={{
						borderRadius: "15px",
						maxHeight: "350px",
						width: "auto",
						mx: "auto",
						mt: "60px",
					}}
				></Box>

			</Container>
			{id === 3 && gender !== "pair" && (
				<Alert
					sx={{
						px: "40px",
						width: "fit-content",
						display: "flex",
						flexWrap: "nowrap",
						mx: "auto",
					}}
					severity="warning"
				>
					Do těchto kurzů se nelze zapsat jako jednotlivec, přepněte se v profilu za pár a poté se zapište.
				</Alert>
			)}
			<BuyCourseDialog
				open={buyCourseDialogOpen}
				setOpen={setBuyCourseDialogOpen}
				setOpenSuccess={setOpenSuccessDialog}
				courseId={id}
				courseName={name}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
				setFinalPrice={setFinalPrice}
			/>
			<SuccessSignUpDialog
				open={openSuccessDialog}
				setOpen={setOpenSuccessDialog}
				description={`Děkujeme za Váš zájem o naše taneční kurzy.
				Platební údaje a potvrzení přihlášky jsme Vám zaslali na Vámi uvedený e-mail.

				Při platbě bankovním převodem, prosím uveďte do poznámky jméno a kurz, který jste objednali. Pokud jste zvolil(a) platbu hotově, můžete zaplatit na příští lekci.
				
				Potvrzení o zaplacení bankovním převodem obdržíte nejpozději do 5. dne následujícího měsíce.
				
				`}
				price={finalPrice}
				bankAccount={id === 7 || id === 714 ? "2102392702/2010" : "2801094173/2010"}
				title="Objednávka úspěšně přijata!"
				refetch=""
			/>
			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
		</Box>
	);
}

CourseContainer.propTypes = {
	courseId: PropTypes.string,
	description: PropTypes.any,
	id: PropTypes.any,
	img: PropTypes.any,
	name: PropTypes.any,
	ref: PropTypes.any
}
