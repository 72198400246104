import PropTypes from "prop-types"
import { useMutation, useQuery } from "@apollo/client";
import {
	Alert,
	Button,
	Container,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import { useCallback, useEffect, useState } from "react";
import {
	BallPartyText,
	DiscoCourseText,
	DiscoIcon,
	DressIcon,
	MenClothingText,
	SnackBarHandler,
	SuitIcon,
	WomenClothingText,
} from "src/atoms";
import {
	DressInfoDialog,
	InfoDialog,
	PaymentDialog,
	SuccessSignUpDialog,
} from "src/organisms";
import { useAuth } from "src/utils/auth";
import jwt_decode from "jwt-decode";
import { route } from "src/Routes";
import { Nightlife } from "@mui/icons-material";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

const ACTIVE_BALLROOM_SEASONS = gql`
	query activeBallroomSeasonsByCourseId($course_id: Int!) {
		activeBallroomSeasonsByCourseId(course_id: $course_id) {
			season_id
			season
			start_date
			end_date
			day
			time
			capacity
			duration
			active
			start_time
			end_time
			women_count
			men_count
			pairs_count
			price
		}
	}
`;

const CREATE_REGISTRATION = gql`
	mutation createRegistration(
		$user_id: Int!
		$season_id: Int!
		$payment: String!
	) {
		createRegistration(
			user_id: $user_id
			season_id: $season_id
			payment: $payment
		) {
			reservation_id
			season_id
			user_id
		}
	}
`;

const USER_INFO = gql`
	query user($user_id: Int!) {
		userById(user_id: $user_id) {
			user_id
			first_name
			last_name
			gender
			email
			street
			city
			zip_code
			house_number
			phone_number
			partners_name
			partners_email
		}
	}
`;

const DAY_MAP = {
	monday: "pondělí",
	tuesday: "úterý",
	wednesday: "středa",
	thursday: "čtvrtek",
	friday: "pátek",
	saturday: "sobota",
	sunday: "neděle",
};

/**
 * Container to display ball dance courses on Courses page.
 * It displays Icon button with dialogs of informational texts, 
 * list of active seasons with possibility to sign up for them if user is signed in.
 * The text displayed is part of editable content and in control of the admin.
 * @param {Number} id course id
 * @param {String} ref reference for smooth scroll after navigation
 * @param {String} description rich text description of course
 * @param {String} name name of the course
 * @returns {Component} MUI Paper with ball dance content 
 */
export function BallDanceContainer({ id, ref, description, name }) {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [user_id] = useState(decoded?.user_id);
	const ballroomSeasonsQuery = useQuery(ACTIVE_BALLROOM_SEASONS, {
		variables: {
			course_id: id,
		},
	});
	const aBallroomCourses = [...ballroomSeasonsQuery.data?.activeBallroomSeasonsByCourseId || []].sort((a, b) => Number(b.start_date) - Number(a.start_date));
	const [gender, setGender] = useState();
	const [seasonID, setSeasonID] = useState();
	const [payment, setPayment] = useState();

	const [openInfoDialog, setOpenInfoDialog] = useState(false);
	const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
	const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const userInfoQuery = useQuery(USER_INFO, {
		variables: {
			user_id: parseInt(user_id),
		},
		skip: !token,
	});

	useEffect(() => {
		if (userInfoQuery.data) {
			setGender(userInfoQuery.data.userById.gender);
		}
	}, [userInfoQuery.data]);

	const [createRegistration] = useMutation(CREATE_REGISTRATION, {
		onCompleted: () => {
			setSnackState("success");
			setSnackMsg("Úspěšná registrace do tanečních");
			setSnackOpen(true);
			setOpenSuccessDialog(true);
			ballroomSeasonsQuery.refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleRegistration = useCallback(
		(user_id, season_id, payment) => {
			let hasFilledAllInfo = true;
			userInfoQuery.data &&
				Object.keys(userInfoQuery.data.userById).forEach(
					(information) => {
						if (id === 1) {
							if (
								(userInfoQuery.data.userById[information] ===
									null ||
									userInfoQuery.data.userById[information] ===
										undefined ||
									userInfoQuery.data.userById[information] ===
										"") &&
								information !== "partners_name" &&
								information !== "partners_email"
							) {
								hasFilledAllInfo = false;
							}
						} else {
							if (
								userInfoQuery.data.userById[information] ===
									null ||
								userInfoQuery.data.userById[information] ===
									undefined ||
								userInfoQuery.data.userById[information] === ""
							) {
								hasFilledAllInfo = false;
							}
						}
					}
				);
			if (hasFilledAllInfo === true) {
				createRegistration({
					variables: {
						user_id: user_id,
						season_id: season_id,
						payment: payment,
					},
				});
			} else {
				setOpenInfoDialog(true);
			}
		},

		[createRegistration, id, userInfoQuery.data]
	);

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<Box
			ref={ref}
			id={id}
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				alignSelf: "center",
				bgcolor: "rgba(255, 255, 255, 0.75)",
				maxWidth: "1600px",
				width: "95%",
				mx: "auto",
				minHeight: "500px",
				borderRadius: "15px",
				my: "50px",
				scrollMarginTop: "120px",
			}}
			component={Paper}
			elevation={16}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					my: "20px",
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					px="40px"
					justifyContent="space-evenly"
					textAlign="justify"
					mx="auto"
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
							flexWrap: "wrap",
						}}
					>
						<Typography
							variant="h3"
							color="initial"
							sx={{
								"&>*": {
									margin: "0px",
									textAlign: "left !important",
									fontSize: "2.5rem",
								},
							}}
							dangerouslySetInnerHTML={createMarkup(
								draftToHtml(JSON.parse(name))
							)}
						></Typography>
						<Box
							sx={{
								display: "flex",
								mx: "10px",
								flexWrap: "wrap",
							}}
						>
							<DressInfoDialog
								icon={<SuitIcon fontSize="2rem" />}
								title="Dress code"
								text={<MenClothingText />}
								tooltip="dress code - muži"
							/>
							<DressInfoDialog
								icon={<DressIcon fontSize="2rem" />}
								title="Dress code"
								text={<WomenClothingText />}
								tooltip="dress code - ženy"
							/>
							<DressInfoDialog
								icon={<DiscoIcon fontSize="2rem" />}
								title="Disco lekce"
								text={<DiscoCourseText />}
								tooltip="disco lekce"
							/>
							<DressInfoDialog
								icon={<Nightlife sx={{ fontSize: "2rem" }} />}
								title="Prodloužená a věneček"
								text={<BallPartyText />}
								tooltip="prodloužená a věneček"
								imgThumb="/img/clothing_thumbnail.png"
								img="/img/clothing.png"
							/>
						</Box>
					</Box>
					<div
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(description))
						)}
					></div>
				</Box>
			</Box>				
			<Container maxWidth="xl" sx={{ mt: "auto", mb: "30px" }}>
				{(id === 1 && gender === "pair") && (
					<Alert
					sx={{
						px: "40px",
						width: "fit-content",
						display: "flex",
						flexWrap: "nowrap",
						mx: "auto",
					}}
					severity="warning"
				>
					Do těchto kurzů se nelze zapsat jako pár, přepněte se v profilu za muže/ženu a poté se zapište.	
				</Alert>
				)}
				<TableContainer>		
					<Table
						sx={{
							minWidth: 650,
							borderSpacing: "0 15px",
							borderCollapse: "separate",
							px: "10px",
						}}
						aria-label="simple table"
					>
						<TableHead>
							<TableRow>
								<TableCell>Ročník</TableCell>
								<TableCell align="center">Termín</TableCell>
								{id === 1 ? (
									<>
										<TableCell align="center">
											Obsazenost ženy
										</TableCell>
										<TableCell align="center">
											Obsazenost muži
										</TableCell>
									</>
								) : (
									<TableCell align="center">
										Obsazenost
									</TableCell>
								)}

								<TableCell align="right"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{aBallroomCourses.map(
									(season) => (
										<TableRow
											key={season.season_id}
											sx={{
												"td:first-of-type": {
													borderRadius:
														"15px 0 0 15px",
												},
												"td:last-of-type": {
													borderRadius:
														"0 15px 15px 0",
												},
												"td, th": {
													fontWeight: "600",
												},
												bgcolor: "primary.main",
												boxShadow:
													"4px 4px 4px 2px rgba(0, 0, 0, 0.25)",
												borderRadius: "15px",
											}}
										>
											<TableCell scope="row">
												{season.season}
											</TableCell>
											<TableCell align="center">
												{DAY_MAP[season.day]}
												{"  "}
												{season.start_time}
												{" - "}
												{season.end_time}
											</TableCell>
											{id === 1 ? (
												<>
													<TableCell align="center">
														{season.women_count}/
														{season.capacity}
													</TableCell>
													<TableCell align="center">
														{season.men_count}/
														{season.capacity}
													</TableCell>
												</>
											) : (
												<TableCell align="center">
													{season.pairs_count}/
													{season.capacity}
												</TableCell>
											)}
											<TableCell align="right">
												{gender === "male" &&
												season.men_count >=
													season.capacity ? (
													<Button
														variant="contained"
														color="secondary"
														sx={{
															mr: "10px",
															textTransform:
																"none",
														}}
														disabled
													>
														Kapacita naplněna
													</Button>
												) : gender === "female" &&
												  season.women_count >=
														season.capacity ? (
													<Button
														variant="contained"
														color="secondary"
														sx={{
															mr: "10px",
															textTransform:
																"none",
														}}
														disabled
													>
														Kapacita naplněna
													</Button>
												) : (
													<Button
														variant="contained"
														color="secondary"
														sx={{
															mr: "10px",
															textTransform:
																"none",
														}}
														onClick={() => {
															setSeasonID(
																season.season_id
															);
															setOpenPaymentDialog(
																true
															);
														}}
														disabled={!user_id || (id === 1 && gender === "pair")}
													>
														Zapsat se
													</Button>
												)}
											</TableCell>
										</TableRow>
									)
								)}
						</TableBody>
					</Table>
				</TableContainer>
				{!token && (
					<Box
						sx={{
							display: "flex",
							mx: "auto",
							flexDirection: "column",
						}}
					>
						<Alert
							sx={{
								px: "40px",
								width: "fit-content",
								display: "flex",
								flexWrap: "nowrap",
								mx: "auto",
							}}
							variant="filled"
							severity="info"
						>
							Pro zápis do tanečních musíte mít založený účet a
							být přihlášen(a).
						</Alert>
						<Box
							sx={{
								display: "flex",
								mx: "auto",
								flexDirection: "row",
								mt: "10px",
							}}
						>
							<Button
								component={Link}
								href={route.signUp()}
								variant="contained"
								color="secondary"
								sx={{ mr: "10px" }}
							>
								Založit účet
							</Button>
							<Button
								component={Link}
								href={route.signIn()}
								variant="contained"
								color="secondary"
								sx={{ ml: "10px" }}
							>
								Přihlásit se
							</Button>
						</Box>
					</Box>
				)}
			</Container>
			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
			<InfoDialog
				open={openInfoDialog}
				setOpen={setOpenInfoDialog}
				title="Chybí osobní údaje"
				description={`Pro zápis do kurzů je nutné mít vyplněny všechny osobní údaje, nejdříve je prosím doplňte, a poté se zapište na kurz.\n Děkujeme za pochopení.`}
			/>
			<PaymentDialog
				open={openPaymentDialog}
				setOpen={setOpenPaymentDialog}
				title="Způsob platby"
				description={`Zvolte, prosím, způsob platby.`}
				action={() => {
					handleRegistration(user_id, seasonID, payment);
				}}
				setPayment={setPayment}
				payment={payment}
				setSnackState={setSnackState}
				setSnackMsg={setSnackMsg}
				setSnackOpen={setSnackOpen}
			/>
			<SuccessSignUpDialog
				open={openSuccessDialog}
				setOpen={setOpenSuccessDialog}
				description={`Děkujeme za Váš zájem o naše taneční kurzy.\nPlatební údaje a potvrzení přihlášky jsme Vám zaslali na Vámi uvedený e-mail.\nPokud jste zvolil(a) platbu hotově, můžete zaplatit na první lekci.`}
				price={aBallroomCourses.filter(course => course.season_id === seasonID)[0]?.price || "N/A"}
				bankAccount="2801094173/2010"
				title="Přihláška úspěšně přijata!"
				refetch=""
			/>
		</Box>
	);
}

BallDanceContainer.propTypes = {
	description: PropTypes.any,
	id: PropTypes.number,
	name: PropTypes.any,
	ref: PropTypes.any
}
