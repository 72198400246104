import { gql, useMutation } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { convertToRaw, EditorState } from "draft-js";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useCallback } from "react";
import { AnnouncementFormFields, SnackBarHandler } from "src/atoms";

const SEND_EMAIL = gql`
	mutation notifyUsers(
		$subject: String!
		$msg: String!
		$user_ids: [Int!]!
		$notification_group: String!
	) {
		notifyUsers(user_ids: $user_ids, subject: $subject, msg: $msg, notification_group: $notification_group)
	}
`;

/**
 * Form to send e-mails.
 * @returns {Component} Form
 */
export function AnnouncementForm() {
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [user_ids, setUser_ids] = useState([]);
	const [announcementSubject, setAnnouncementSubject] = useState("");
	const [message, setMessage] = useState(() =>
		EditorState.createEmpty()
	);
	const [selectedGroup, setSelectedGroup] = useState("");

	const initialValues = {
		user_ids: user_ids,
		announcementSubject: announcementSubject,
		message: message,
	};

	const [sendEmailRequest] = useMutation(SEND_EMAIL, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.notifyUsers);
			setSnackOpen(true);
			window.location.reload();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSendEmailSubmit = useCallback(
		(values) => {
			sendEmailRequest({
				variables: {
          			user_ids: user_ids,
					subject: values.announcementSubject,
					msg: JSON.stringify(
						convertToRaw(message.getCurrentContent())
					),
					notification_group: selectedGroup
				},
			});
		},
		[message, selectedGroup, sendEmailRequest, user_ids]
	);

	return (
		<>
			<Formik
				onSubmit={handleSendEmailSubmit}
				initialValues={initialValues}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<AnnouncementFormFields
							setUser_ids={setUser_ids}
							setAnnouncementSubject={setAnnouncementSubject}
							setMessage={setMessage}
							user_ids={user_ids}
							announcementSubject={announcementSubject}
							message={message}
							setSelectedGroup={setSelectedGroup}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							ml: "auto",
						}}
					>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ml: 'auto'}}
						>
							Odeslat
						</Button>
					</Box>
				</Form>
			</Formik>

			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
		</>
	);
}
