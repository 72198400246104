import PropTypes from "prop-types"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, TextField } from "@mui/material";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { Form, Formik } from "formik";
import { useState } from "react";

const USERS = gql`
	query users {
		users {
			user_id
			first_name
			last_name
			email
		}
	}
`;

const ADD_LECTURER = gql`
	mutation newLecturer($user_id: Int!) {
		newLecturer(user_id: $user_id)
	}
`;

/**
 * Dialog to add new lecturer chosen from autocomplete list.
 * @param {Boolean} open open/close dialog state
 * @param {Function} setOpen open/close dialog
 * @param {Function} refetch refetch new data from database
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @returns {Component} Dialog
 */
export function AddLecturerDialog({
	open,
	setOpen,
	refetch,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
}) {
  const [userID, setUserID] = useState("");

	const { data } = useQuery(USERS);

	const handleClose = (event, reason) => {
		if (reason !== "backdropClick") {
			setOpen(false);
		}
	};

	const [addLecturerRequest] = useMutation(ADD_LECTURER, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.newLecturer);
			setSnackOpen(true);
      refetch();
			handleClose();
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSubmit = useCallback(
		() => {
			addLecturerRequest({
				variables: {
					user_id: parseInt(userID)
				},
			});
		},
		[addLecturerRequest, userID]
	);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth={true}
			transitionDuration={500}
		>
			<Formik
				onSubmit={handleSubmit}
				initialValues={{ selectedUser: "" }}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<DialogTitle>Vyberte uživatele</DialogTitle>
					<DialogContent sx={{ height: "300px" }}>
						<Autocomplete
							disablePortal
							id="combo-box-demo"
							options={data ? data.users : []}
							getOptionLabel={(option) =>
								`${option.first_name} ${option.last_name} - ${option.email}`
							}
							sx={{ width: "100%", mt: "20px" }}
              onChange={(e, value)=>{
                setUserID(value.user_id);
              }}
							renderInput={(params) => (
								<TextField
									name="selectedUser"
									color="secondary"
									{...params}
									label="Uživatelé"
								/>
							)}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Přidat
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

AddLecturerDialog.propTypes = {
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
