import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";


/**
 * Homepage section with contact information and 2 maps.
 * @returns {Component} Grid in Boxes - Contact section
 */
export function HomepageContact() {
	const mobile = useMediaQuery("(max-width:450px)");

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				backgroundImage: "url(/img/ballet_dancer.png)",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center",
				scrollMarginTop: "120px",
				flexWrap: "wrap",
			}}
			id="Kontakty"
		>
			<Box
				sx={{
					bgcolor: "rgba(0,0,0,0.75)",
					height: "100%",
					width: "100%",
					flexDirection: "row",
					display: "flex",
					flexWrap: "wrap",
					py: "60px",
					px: "24px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						flexGrow: "1",
					}}
				>
					<Typography color="white" variant="h3">
						Kontakt
					</Typography>
					<Typography color="white" variant="h4">
						Kateřina Motyčková
					</Typography>
					<Typography color="white" variant="body1">
						+420 777 621 941
					</Typography>
					<Typography color="white" variant="body1">
						motyckovakaterina@gmail.com
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							width: "100%",
							justifyContent: "space-between",
							mt: "20px",
							flexWrap: "wrap",
						}}
					>
						<Grid container spacing={8}>
							<Grid item xs={12} lg={4}>
								<Box
									sx={{
										display: "flex",
										width: "100%",
										flexDirection: "column",
									}}
								>
									<Typography color="white" variant="h5">
										Kurzy tanečního studia
									</Typography>
									<Typography color="white" variant="body1">
										ZŠ Komenského 269, 561 51 Letohrad
									</Typography>
									<Box
										alt="map"
										sx={{
											width: "100%",
										}}
									>
										<iframe
											title="ZS komenskeho map"
											src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2154.9037742081846!2d16.493935872461858!3d50.0370866224045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dfae0c4c9b9f7%3A0x8130490e21549a9f!2sKomensk%C3%A9ho%20269%2C%20561%2051%20Letohrad!5e0!3m2!1scs!2scz!4v1662049050804!5m2!1scs!2scz"
											height={mobile ? "300" : "400"}
											width={mobile ? "100%" : "100%"}
											style={{ border: "0" }}
											allowFullScreen=""
											loading="lazy"
											referrerPolicy="no-referrer-when-downgrade"
										></iframe>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} lg={4}>
								<Box
									sx={{
										display: "flex",
										width: "100%",
										flexDirection: "column",
									}}
								>
									<Typography color="white" variant="h5">
										Kurzy párových tanců
									</Typography>
									<Typography color="white" variant="body1">
										Dům kultury, Družstevní 597, 561 51 Letohrad
									</Typography>
									<Box
										alt="map"
										sx={{
											width: "100%",
										}}
									>
										<iframe
											title="kulturnidum_map"
											src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2562.7637736698075!2d16.5022299!3d50.0345221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dfafc78a18d07%3A0xe7ad014b80785077!2sD%C5%AFm%20kultury!5e0!3m2!1scs!2scz!4v1710755217108!5m2!1scs!2scz"
											height={mobile ? "300" : "400"}
											width={mobile ? "100%" : "100%"}
											style={{ border: "0" }}
											allowFullScreen=""
											loading="lazy"
											referrerPolicy="no-referrer-when-downgrade"
										></iframe>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} lg={4}>
								<Box
									sx={{
										display: "flex",
										width: "100%",
										flexDirection: "column",
									}}
								>
									<Typography color="white" variant="h5">
										Kurzy párových tanců
									</Typography>
									<Typography color="white" variant="body1">
										Orlovna č.p. 181, Letohrad - Orlice
									</Typography>
									<Box
										alt="map"
										sx={{
											width: "100%",
										}}
									>
										<iframe
											title="orlice_map"
											src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.0979557758756!2d16.5232219!3d50.0282603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dfa571f53820b%3A0x126725b800e38190!2sOrlice%20181%2C%20561%2051%20Letohrad!5e0!3m2!1scs!2scz!4v1657278024537!5m2!1scs!2scz"
											height={mobile ? "300" : "400"}
											width={mobile ? "100%" : "100%"}
											style={{ border: "0" }}
											allowFullScreen=""
											loading="lazy"
											referrerPolicy="no-referrer-when-downgrade"
										></iframe>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
