import PropTypes from "prop-types"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AccountBalance, Payments } from "@mui/icons-material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DialogContentText, TextField } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import cs from "date-fns/locale/cs";

/**
 * Dialog to confirm or deny received payment for lecturer.
 * Lecturer sets the date of received payment.
 * Lecturer can also opt for Payment not received, which restart the payment dates as well.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} action function after submitting dialog
 * @param {Function} setPayment function to set the payment method
 * @param {Function} setPaymentDate function to set the payment date
 * @param {Date} payment_date date of the payment
 * @param {String} payment the payment method
 * @param {Function} handlePaymentNotReceived function to set payment to false and delete payment date
 * @returns {Component} Dialog
 */
export function ManagePaymentDialog({
	open,
	setOpen,
	action,
	setPayment,
	setPaymentDate,
	payment_date,
	payment,
	handlePaymentNotReceived
}) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="sm"
			fullWidth={true}
		>
			<DialogTitle>Zaznamenat platbu</DialogTitle>
			<DialogContent>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
					<DatePicker
						mask="__.__.____"
						label="Datum platby"
						value={payment_date}
						onChange={(newValue) => {
							setPaymentDate(newValue);
						}}
						renderInput={(params) => (
							<TextField
								sx={{ my: "20px" }}
								color="secondary"
								size="small"
								{...params}
							/>
						)}
					/>
				</LocalizationProvider>
				<DialogContentText>Způsob platby</DialogContentText>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						mt: "15px",
					}}
				>
					<Button
						onClick={() => {
							setPayment("cash");
						}}
						color={payment === "cash" ? "primary" : "neutral"}
						variant="contained"
						sx={{ width: "250px" }}
						endIcon={<Payments />}
					>
						Hotově
					</Button>
					<Button
						color={
							payment === "bank_transfer" ? "primary" : "neutral"
						}
						variant="contained"
						onClick={() => {
							setPayment("bank_transfer");
						}}
						sx={{ width: "250px" }}
						endIcon={<AccountBalance />}
					>
						Bankovním převodem
					</Button>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={()=>{handlePaymentNotReceived()}}
					color="wine"
					variant="contained"
					sx={{ mr: "auto", ml: "10px" }}
				>
					Neuhradil(a)
				</Button>
				<Button
					onClick={() => {
						action();
						handleClose();
					}}
					color="secondary"
					variant="contained"
				>
					Potvrdit
				</Button>
				<Button onClick={handleClose} color="secondary">
					Zrušit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

ManagePaymentDialog.propTypes = {
	action: PropTypes.func,
	handlePaymentNotReceived: PropTypes.func,
	open: PropTypes.any,
	payment: PropTypes.string,
	payment_date: PropTypes.any,
	setOpen: PropTypes.func,
	setPayment: PropTypes.func,
	setPaymentDate: PropTypes.func
}
