import PropTypes from "prop-types"
import { gql, useMutation } from "@apollo/client";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback } from "react";

import { FormikField } from "src/molecules";

const NEW_ALBUM = gql`
mutation newAlbum($name: String!){
  newAlbum(name: $name)
}
`;
/**
 * Dialog to create new album.
 * Has album name input.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} refetch refetch new data from database
 * @returns {Component} Dialog
 */
export function NewAlbumDialog({
	open,
	setOpen,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
	refetch,
}) {
	const [addNewAlbumRequest] = useMutation(NEW_ALBUM, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.newAlbum);
			setSnackOpen(true);
			handleClose();
      refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleAddNewAlbum = useCallback(
		(values) => {
			addNewAlbumRequest({
				variables: {
					name: values.name,
				},
			});
		},
		[addNewAlbumRequest]
	);

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="md"
			fullWidth={true}
		>
			<Formik
				onSubmit={handleAddNewAlbum}
				initialValues={{
					name: "",
				}}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<DialogTitle id="alert-dialog-title" >
						{"Přidat album"}
					</DialogTitle>
					<DialogContent>
						<FormikField
							id="name"
							name="name"
							label="Název"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
              sx={{mt: '10px'}}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Uložit
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

NewAlbumDialog.propTypes = {
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
