import { useMutation, useQuery } from "@apollo/client";
import {
	Cancel,
	CheckCircleOutline,
	HelpOutline,
	HighlightOff,
	PivotTableChart,
	Visibility,
	VisibilityOff,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import cs from "date-fns/locale/cs";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	IconButton,
	Toolbar,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	TextField,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import moment from "moment";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { convertFromRaw } from "draft-js";
import { DeleteDialog } from "src/organisms";
import { AdminPanel } from "src/templates";

const RESERVATIONS = gql`
	query getReservationsCurrentSeason {
		allReservationsCurrentSeason {
			reservation_id
			course_id
			season_id
			event_id
			user_id
			event_date
			gender
			event_start
			event_end
			duration
			capacity
			min_participation
			reservation_count
			name
			first_name
			last_name
			email
			attended
			signed_out
			men_count
			women_count
			pairs_count
		}
	}
`;

const COURSES_LIST = gql`
	query coursesList {
		courses {
			course_id
			name
		}
	}
`;

const SEASONS_LIST = gql`
	query ballroomSeasonsByCourseId($course_id: Int) {
		ballroomSeasonsByCourseId(course_id: $course_id) {
			season_id
			course_id
			season
			day
		}
	}
`;

const REMOVE_RESERVATION = gql`
	mutation reservationRemoval($reservation_id: Int!) {
		removeCourseReservation(reservation_id: $reservation_id)
	}
`;

const DAY_MAP = {
	monday: "pondělí",
	tuesday: "úterý",
	wednesday: "středa",
	thursday: "čtvrtek",
	friday: "pátek",
	saturday: "sobota",
	sunday: "neděle",
};

/**
 * Dance studio reservations administration content.
 * Used to display table with all reservations.
 * Enables filtering data and export it to excel. Also can delete these reservations.
 * @returns {Component} Admin panel content for reservation management
 */
export function ReservationsManagement() {
	const exportTableRef = useRef(null);

	const [showMore, setShowMore] = useState("none");
	const [courseText, setCourseText] = useState("");
	const [seasonText, setSeasonText] = useState("");
	const [genderText, setGenderText] = useState("");

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);
	const [dialogID, setdialogID] = useState(false);
	const [deleteFunction, setDeleteFunction] = useState();

	const [courseNameFilterValue, setCourseNameFilterValue] = useState("");
	const [seasonFilterValue, setSeasonFilterValue] = useState("");
	const [genderFilter, setGenderFilterValue] = useState("");
	const [dateFilter, setDateFilter] = useState(null);

	const eventsQuery = useQuery(RESERVATIONS);
	const coursesListQuery = useQuery(COURSES_LIST);
	const seasonsListQuery = useQuery(SEASONS_LIST, {
		variables: {
			course_id: parseInt(courseNameFilterValue),
		},
	});

	const [removeReservationRequest] = useMutation(REMOVE_RESERVATION, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.removeCourseReservation);
			setSnackOpen(true);
			eventsQuery.refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	let reservations = [];

	if (eventsQuery.data && eventsQuery.data.allReservationsCurrentSeason !== null) {
		reservations = eventsQuery.data.allReservationsCurrentSeason.filter(
			(reservation) => {
				if (
					courseNameFilterValue !== "" &&
					reservation.course_id !== courseNameFilterValue
				) {
					return false;
				}
				if (
					seasonFilterValue !== "" &&
					reservation.season_id !== seasonFilterValue
				) {
					return false;
				}
				if (
					dateFilter !== null &&
					!moment(new Date(parseInt(reservation.event_date))).isSame(
						dateFilter,
						"day"
					)
				) {
					return false;
				}
				if (
					genderFilter !== "" &&
					reservation.gender !== genderFilter
				) {
					return false;
				}
				return true;
			}
		);
	}

	const handleRemoveReservation = useCallback(
		(reservation_id) => {
			removeReservationRequest({
				variables: {
					reservation_id: parseInt(reservation_id),
				},
			});
		},
		[removeReservationRequest]
	);

	const handleCourseFilter = (event) => {
		setCourseNameFilterValue(event.target.value);
		setSeasonFilterValue("");
	};

	const handleSeasonFilter = (event) => {
		setSeasonFilterValue(event.target.value);
	};
	const handleGenderFilter = (event) => {
		setGenderFilterValue(event.target.value);
	};

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<AdminPanel
			title="Výpis rezervací"
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Toolbar>
				<FormControl
					sx={{ m: 1, minWidth: 250, maxWidth: 300 }}
					size="small"
					color="secondary"
				>
					<InputLabel id="demo-select-small">Název kurzu</InputLabel>
					<Select
						labelId="course-name-select-id-label"
						id="course-name-select"
						value={courseNameFilterValue}
						onChange={(e) => {
							handleCourseFilter(e);
							setTimeout(() => {
								setCourseText(
									document.getElementById(
										"course-name-select"
									).outerText
								);
							}, 300);
						}}
						label="Název kurzu"
					>
						<MenuItem value="">
							<em>Žádný</em>
						</MenuItem>
						{coursesListQuery.data &&
							coursesListQuery.data.courses.map((course) => (
								<MenuItem
									key={course.course_id}
									value={course.course_id}
								>
									<Typography
										variant="body1"
										sx={{
											"&>*": {
												margin: "0px",
											},
										}}
										dangerouslySetInnerHTML={createMarkup(
											draftToHtml(JSON.parse(course.name))
										)}
									></Typography>
								</MenuItem>
							))}
					</Select>
				</FormControl>

				<FormControl
					sx={{ m: 1, minWidth: 140, maxWidth: 250 }}
					size="small"
					color="secondary"
				>
					<InputLabel id="demo-select-small">Sezóna</InputLabel>
					<Select
						labelId="season-select-id-label"
						id="season-select"
						value={seasonFilterValue}
						onChange={(e) => {
							handleSeasonFilter(e);
							setTimeout(() => {
								setSeasonText(
									document.getElementById("season-select")
										.outerText
								);
							}, 300);
						}}
						label="Sezóna"
					>
						<MenuItem value="">
							<em>Žádná</em>
						</MenuItem>
						{seasonsListQuery.data &&
							seasonsListQuery.data.ballroomSeasonsByCourseId.map(
								(season) => (
									<MenuItem
										key={season.season_id}
										value={season.season_id}
									>
										{season.season} - {DAY_MAP[season.day]}
									</MenuItem>
								)
							)}
					</Select>
				</FormControl>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
					<DatePicker
						mask="__.__.____"
						label="Datum"
						value={dateFilter}
						onChange={(newValue) => {
							setDateFilter(newValue);
						}}
						renderInput={(params) => (
							<TextField
								color="secondary"
								size="small"
								{...params}
							/>
						)}
					/>
				</LocalizationProvider>
				<FormControl
					sx={{ m: 1, minWidth: 140, maxWidth: 200 }}
					size="small"
					color="secondary"
				>
					<InputLabel id="demo-select-small">Pohlaví</InputLabel>
					<Select
						labelId="gender-select-id-label"
						id="gender-select"
						value={genderFilter}
						onChange={(e) => {
							handleGenderFilter(e);
							setTimeout(() => {
								setGenderText(
									document.getElementById("gender-select")
										.outerText
								);
							}, 300);
						}}
						label="Pohlaví"
					>
						<MenuItem value="">
							<em>Žádné</em>
						</MenuItem>
						<MenuItem key="male" value="male">
							Muž
						</MenuItem>
						<MenuItem key="female" value="female">
							Žena
						</MenuItem>
						<MenuItem key="pair" value="pair">
							Pár
						</MenuItem>
					</Select>
				</FormControl>
				<DownloadTableExcel
					filename={
						courseText !== "" ||
						seasonText !== "" ||
						genderText !== ""
							? `${courseText} ${seasonText} ${genderText}`
							: "Rezervace TSK"
					}
					sheet={
						courseText !== "" ||
						seasonText !== "" ||
						genderText !== ""
							? `${courseText} ${seasonText} ${genderText}`
							: "Rezervace TSK"
					}
					currentTableRef={exportTableRef.current}
				>
					<Tooltip title="Export do excelu">
						<IconButton
							variant="contained"
							color="secondary"
							sx={{ ml: "auto", mr: "5px" }}
						>
							<PivotTableChart />
						</IconButton>
					</Tooltip>
				</DownloadTableExcel>
				<Tooltip
					title={showMore === "none" ? "Ukázat více" : "Ukázat méně"}
				>
					<IconButton
						variant="contained"
						color="secondary"
						onClick={() => {
							showMore === "none"
								? setShowMore("table-cell")
								: setShowMore("none");
						}}
					>
						{showMore === "none" ? (
							<Visibility />
						) : (
							<VisibilityOff />
						)}
					</IconButton>
				</Tooltip>
			</Toolbar>
			{!eventsQuery.loading ? (
				<TableContainer sx={{ maxHeight: "400px", minHeight: "300px" }}>
					<Table
						sx={{
							minWidth: 650,
							borderCollapse: "separate",
							px: "10px",
						}}
						aria-label="simple table"
						ref={exportTableRef}
						size="small"
					>
						<TableHead>
							<TableRow>
								<TableCell>Datum</TableCell>
								<TableCell align="center">Příjmení</TableCell>
								<TableCell align="center">Jméno</TableCell>
								<TableCell
									align="center"
									sx={{ display: showMore }}
								>
									Kurz
								</TableCell>
								<TableCell align="center">
									Čas začátku
								</TableCell>
								<TableCell align="center">Čas konce</TableCell>

								<TableCell align="center">E-mail</TableCell>
								<TableCell
									align="center"
									sx={{ display: showMore }}
								>
									Účastnil se
								</TableCell>
								<TableCell align="center">Kapacita</TableCell>
								<TableCell align="right"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{eventsQuery.data &&
								eventsQuery.data.allReservationsCurrentSeason !== null &&
								reservations.map((reservation) => (
									<TableRow key={reservation.reservation_id}>
										<TableCell align="left">
											{reservation.event_start.match(
												/.*(?=\s\d.*:)/g
											)}
										</TableCell>
										<TableCell align="center">
											{reservation.last_name}
										</TableCell>
										<TableCell align="center">
											{reservation.first_name}
										</TableCell>
										<TableCell
											align="center"
											sx={{ display: showMore }}
										>
											{convertFromRaw(
												JSON.parse(reservation.name)
											).getPlainText()}
										</TableCell>
										<TableCell align="center">
											{
												reservation.event_start.match(
													/\s(\d*:\d*)/g
												)[0]
											}
										</TableCell>
										<TableCell align="center">
											{
												reservation.event_end.match(
													/\s(\d*:\d*)/g
												)[0]
											}
										</TableCell>

										<TableCell align="center">
											{reservation.email}
										</TableCell>
										<TableCell
											align="center"
											sx={{ display: showMore }}
										>
											{reservation.attended === false ? (
												<HighlightOff
													sx={{
														color: "response.error",
													}}
												/>
											) : reservation.attended ===
											  true ? (
												<CheckCircleOutline
													sx={{
														color: "response.success",
													}}
												/>
											) : (
												<HelpOutline
													sx={{
														color: "#e69602",
													}}
												/>
											)}
										</TableCell>
										{reservation.course_id > 3 ? (
											<TableCell align="center">
												{reservation.reservation_count}{" "}
												/ {reservation.capacity}
											</TableCell>
										) : (
											<>
												{reservation.gender !==
												"pair" ? (
													<TableCell
														align="center"
														sx={{
															whiteSpace:
																"pre-line",
														}}
													>
														{"Ž "}
														{
															reservation.women_count
														}{" "}
														/ {reservation.capacity}
														{"\n"}
														{"M "}
														{
															reservation.men_count
														}{" "}
														/ {reservation.capacity}
													</TableCell>
												) : (
													<TableCell
														align="center"
														sx={{
															whiteSpace:
																"pre-line",
														}}
													>
														{
															reservation.pairs_count
														}{" "}
														/ {reservation.capacity}
													</TableCell>
												)}
											</>
										)}
										<TableCell align="right">
											<IconButton
												aria-label="cancel-event"
												onClick={() => {
													setOpenDialog(true);
													setdialogID(
														reservation.reservation_id
													);
													setDeleteFunction(
														() =>
															handleRemoveReservation
													);
												}}
												color="error"
											>
												<Cancel />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Box
					sx={{
						width: "100%",
						display: "flex",
						mx: "auto",
						mt: "auto",
						height: "400px",
						alignItems: "center",
					}}
				>
					<CircularProgress color="secondary" sx={{ mx: "auto" }} />
				</Box>
			)}
			<DeleteDialog
				open={openDialog}
				setOpen={setOpenDialog}
				id={dialogID}
				deleteFunction={deleteFunction}
			/>
		</AdminPanel>
	);
}
