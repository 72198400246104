import { gql, useQuery } from "@apollo/client";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useAuth } from "src/utils/auth";
import jwt_decode from "jwt-decode";
import { SnackBarHandler } from "src/atoms";
import { QrCodeScanDialogStudent } from "src/organisms";

const USER_INFO = gql`
	query user($user_id: Int!) {
		userById(user_id: $user_id) {
			user_id
			first_name
			last_name
			role
			gender
		}
	}
`;

/**
 * So called Prezenčka.
 * Enables student to take a photo of a events QR code to confirm their attendance.
 * @returns {Component} Page with button to open QR scanner
 */
export function StudentAttendancePage() {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [user_id] = useState(decoded?.user_id);
	const mobile = useMediaQuery("(max-width:600px)");

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [openQrScan, setOpenQrScan] = useState(true);

	const getUserInfo = useQuery(USER_INFO, {
		variables: { user_id: parseInt(user_id) },
	});

	let user = {
		first_name: "",
		last_name: "",
		role: "",
	};

	if (getUserInfo.data) {
		Object.keys(user).forEach((key) => {
			user[key] = getUserInfo.data.userById[key];
		});
	}

	return (
		<>
			<Box
				className="noScrollBarListItem"
				sx={{
					maxWidth: "800px",
					mx: "auto",
					mb: "30px",
					transform: "translateZ(0px)",
					overflowY: "scroll",
					p: "20px",
					py: "50px",
				}}
				maxHeight={mobile && "600px"}
				height={!mobile && "100%"}
				minHeight={!mobile && "inherit"}
			>
				<Typography variant="h2" color="initial" textAlign="center">
					Potvrdit účast na hodině
				</Typography>
				<Button
					variant="contained"
					size="large"
					sx={{mx: 'auto', display: "flex", mt: "100px"}}
					onClick={() => {
						setOpenQrScan(true);
					}}
				>
					Naskenovat QR kód
				</Button>
			</Box>
			<SnackBarHandler
				snackState={snackState}
				snackMsg={snackMsg}
				snackOpen={snackOpen}
				setSnackOpen={setSnackOpen}
			/>
			<QrCodeScanDialogStudent
				title="Naskenovat QR kód"
				open={openQrScan}
				setOpen={setOpenQrScan}
				user_id={user_id}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
			/>
		</>
	);
}
