import { gql, useMutation, useQuery } from "@apollo/client";
import {
	Check,
	Close,
	EventBusy,
	Print,
	QrCodeScanner,
	Undo,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
	Paper,
	TextField,
	Typography,
	MenuItem,
	Select,
	Stack,
	FormControl,
	InputLabel,
	SpeedDial,
	SpeedDialIcon,
	SpeedDialAction,
	useMediaQuery,
	IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import cs from "date-fns/locale/cs";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useAuth } from "src/utils/auth";
import jwt_decode from "jwt-decode";
import { useCallback } from "react";
import { AdminsOnly, ConfirmEventNotHeldDialog, QrCodeScanDialog, SignInRequest } from "src/organisms";
import { convertFromRaw } from "draft-js";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { Banner, SnackBarHandler } from "src/atoms";

const USER_INFO = gql`
	query user($user_id: Int!) {
		userById(user_id: $user_id) {
			user_id
			first_name
			last_name
			role
			gender
		}
	}
`;

const EVENTS_LIST = gql`
	query eventsByDate($date: String) {
		eventsByDate(date: $date) {
			event_id
			course_id
			name
			event_start
			event_date
			held
		}
	}
`;
const ATTENDEES_LIST = gql`
	query eventReservations($event_id: Int) {
		eventReservations(event_id: $event_id) {
			reservation_id
			first_name
			last_name
			partners_name
			attended
			signed_out
			gender
		}
	}
`;

const SET_ATTENDANCE = gql`
	mutation setAttendance($reservation_id: Int!, $attended: Boolean) {
		setAttendance(reservation_id: $reservation_id, attended: $attended)
	}
`;

const SWITCH_HELD_EVENT = gql`
	mutation switchHeldEvent($event_id: Int!, $held: Boolean!) {
		switchHeldEvent(event_id: $event_id, held: $held)
	}
`;

const attendedStyle = {
	width: "100%",
	mx: "auto",
	px: "10px",
	py: "5px",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	bgcolor: "response.success",
};
const notPresentStyle = {
	width: "100%",
	mx: "auto",
	px: "10px",
	py: "5px",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	bgcolor: "response.error",
};
const simpleStyle = {
	width: "100%",
	mx: "auto",
	px: "10px",
	py: "5px",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};


/**
 * So called Prezenčka.
 * After selecting date and event, shows all signed in users and lecturer can confirm or disprove student's attendance.
 * Lecturer can also print list of users and use QR code or QR code scanner to fill the attendance.
 * Users either take a photo of QR above the list or lecturers takes photo of theirs.
 * @returns {Component} Stack of Inputs and users and SpeedDial for other functions
 */
export function AttendancePage() {
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [user_id] = useState(decoded?.user_id);
	const [role] = useState(decoded?.role);
	const mobile = useMediaQuery("(max-width:600px)");
	const printQrRef = useRef();

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [openConfirmHeldDialog, setOpenConfirmHeldDialog] = useState(false);

	const [held, setHeld] = useState();
	const [event_id, setEvent_id] = useState("");

	const [event, setEvent] = useState("");
	const [men, setMen] = useState("");
	const [women, setWomen] = useState("");
	const [pairs, setPairs] = useState("");
	const [openQrScan, setOpenQrScan] = useState(false);
	const [event_date, setEventDate] = useState(new Date());

	const [setEventIsHeldRequest] = useMutation(SWITCH_HELD_EVENT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.switchHeldEvent);
			setSnackOpen(true);
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const actions = [
		{
			icon: <QrCodeScanner />,
			name: "Qr kód",
			action: () => {
				setOpenQrScan(true);
			},
		},
		{
			icon: <Print />,
			name: "Vytisknout",
			action: useReactToPrint({
				content: () => printQrRef.current,
			}),
		},
		{
			icon: <EventBusy />,
			name: "Zrušit lekci",
			action: () => {
				setOpenConfirmHeldDialog(true);
				setHeld(false);
			},
		},
	];

	const getUserInfo = useQuery(USER_INFO, {
		variables: { user_id: parseInt(user_id) },
	});

	const getEventsByDate = useQuery(EVENTS_LIST, {
		variables: {
			date: `${event_date.getFullYear()}-${
				event_date.getMonth() + 1
			}-${event_date.getDate()}`,
		},
	});

	const attendeesQuery = useQuery(ATTENDEES_LIST, {
		variables: {
			event_id: parseInt(event_id),
		},
	});

	let user = {
		first_name: "",
		last_name: "",
		role: "",
	};

	useEffect(() => {
		if (getEventsByDate.data && event_id !== "" && event_id !== null) {
			setEvent(
				getEventsByDate.data.eventsByDate.filter((event) => {
					return event.event_id === event_id;
				})[0]
			);
		}
	}, [event_id, getEventsByDate.data]);

	if (getUserInfo.data) {
		Object.keys(user).forEach((key) => {
			user[key] = getUserInfo.data.userById[key];
		});
	}

	const handleChange = (event) => {
		setEvent_id(event.target.value);
	};

	const [setAttendanceQuery] = useMutation(SET_ATTENDANCE, {
		onCompleted: (data) => {
			attendeesQuery.refetch();
		},
		onError: (error) => {},
	});

	const setAttendance = useCallback(
		(reservation_id, attended) => {
			setAttendanceQuery({
				variables: {
					reservation_id: parseInt(reservation_id),
					attended: attended,
				},
			});
		},
		[setAttendanceQuery]
	);

	const handleEventIsHeld = useCallback(
		(event_id, held) => {
			setEventIsHeldRequest({
				variables: {
					event_id: event_id,
					held: held,
				},
			});
		},
		[setEventIsHeldRequest]
	);

	useEffect(() => {
		if (attendeesQuery.data) {
			const countMen = attendeesQuery.data.eventReservations.filter(
				(attendant) => {
					if (
						attendant.gender === "male" &&
						attendant.signed_out === false
					) {
						return true;
					}
					return false;
				}
			);
			setMen(countMen.length);
			const countWomen = attendeesQuery.data.eventReservations.filter(
				(attendant) => {
					if (
						attendant.gender === "female" &&
						attendant.signed_out === false
					) {
						return true;
					}
					return false;
				}
			);
			setWomen(countWomen.length);
			const countPairs = attendeesQuery.data.eventReservations.filter(
				(attendant) => {
					if (
						attendant.gender === "pair" &&
						attendant.signed_out === false
					) {
						return true;
					}
					return false;
				}
			);
			setPairs(countPairs.length);
		}
	}, [attendeesQuery.data]);

	return (
		<>
			{!token ? (
				<SignInRequest />
			) : role !== "admin" && role !== "lecturer" ? (
				<AdminsOnly />
			) : (
				<>
					<Box
						className="noScrollBarListItem"
						sx={{
							maxWidth: "800px",
							mx: "auto",
							mb: "30px",
							transform: "translateZ(0px)",
							overflowY: "scroll",
							p: "20px",
							py: "50px",
						}}
						maxHeight={mobile && "600px"}
						height={!mobile && "100%"}
						minHeight={!mobile && "inherit"}
						ref={printQrRef}
					>
						<Stack
							sx={{ maxWidth: "400px", mx: "auto" }}
							spacing={4}
						>
							<Typography
								variant="h2"
								color="initial"
								textAlign="center"
							>
								Prezenčka
							</Typography>
							{event_id !== "" && event_id !== null && (
								<Paper
									sx={{
										p: "15px",
										mb: "20px",
										mx: "auto !important",
										alignItems: "center",
										display: "flex",
									}}
								>
									<QRCode
										value={event_id.toString()}
										size={125}
									/>
								</Paper>
							)}

							<Paper
								elevation={2}
								sx={{ width: "100%", mx: "auto", p: "15px" }}
							>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									locale={cs}
								>
									<DatePicker
										mask="__.__.____"
										label="Datum konání"
										value={event_date}
										onChange={(newValue) => {
											setEventDate(newValue);
											setEvent_id("");
										}}
										renderInput={(params) => (
											<TextField
												sx={{ width: "100%" }}
												color="secondary"
												size="small"
												{...params}
											/>
										)}
									/>
								</LocalizationProvider>
							</Paper>
							<Paper
								elevation={2}
								sx={{ width: "100%", mx: "auto", p: "15px" }}
							>
								<FormControl
									fullWidth
									color="secondary"
									size="small"
								>
									<InputLabel id="event-select-label">
										Kurz
									</InputLabel>
									<Select
										labelId="event-select"
										id="event-select"
										value={event_id}
										label="Kurz"
										onChange={handleChange}
									>
										<MenuItem value="">
											<em>Žádný</em>
										</MenuItem>
										{getEventsByDate.data &&
											getEventsByDate.data.eventsByDate.map(
												(event) => (
													<MenuItem
														key={event.event_id}
														value={event.event_id}
													>
														{
															event.event_start.match(
																/\s(\d{0,2}:\d{0,2})/
															)[0]
														}
														{" - "}
														{convertFromRaw(
															JSON.parse(
																event.name
															)
														).getPlainText()}
													</MenuItem>
												)
											)}
									</Select>
								</FormControl>
							</Paper>
							<Stack spacing={2}>
								{event !== "" &&
									event !== null &&
									event.held === false && (
										<Banner title="Lekce zrušena" severity="error" />
									)}
								{attendeesQuery.data &&
									attendeesQuery.data.eventReservations
										.length > 0 && (
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
											}}
										>
											{event !== "" &&
											event !== null &&
											event.course_id !== 2 ? (
												<>
													<Typography
														variant="body1"
														color="initial"
														sx={{
															whiteSpace:
																"pre-line",
														}}
													>
														Muži: {men}
													</Typography>
													<Typography
														variant="body1"
														color="initial"
														sx={{
															whiteSpace:
																"pre-line",
														}}
													>
														Ženy: {women}
													</Typography>
												</>
											) : (
												<Typography
													variant="body1"
													color="initial"
													sx={{
														whiteSpace: "pre-line",
													}}
												>
													Páry: {pairs}
												</Typography>
											)}
										</Box>
									)}
								{attendeesQuery.data &&
									attendeesQuery.data.eventReservations.map(
										(attendee) => (
											<Paper
												key={attendee.reservation_id}
												elevation={4}
												sx={
													attendee.attended === true
														? attendedStyle
														: attendee.attended ===
														  false
														? notPresentStyle
														: simpleStyle
												}
												background="primary.main"
											>
												<Typography
													variant="body1"
													color="initial"
													marginY="auto"
												>
													{attendee.last_name}{" "}
													{attendee.first_name}
													{event !== "" &&
														event !== null &&
														event.course_id ===
															2 && (
															<>
																{", "}{
																	attendee.partners_name
																}
															</>
														)}
												</Typography>
												{attendee.signed_out ===
												false ? (
													attendee.attended !==
													null ? (
														<IconButton
															aria-label=""
															onClick={() => {
																setAttendance(
																	attendee.reservation_id,
																	null
																);
															}}
															sx={{
																color: "black",
															}}
														>
															<Undo fontSize="large" />
														</IconButton>
													) : (
														<Box
															sx={{
																display: "flex",
															}}
														>
															<IconButton
																aria-label=""
																onClick={() => {
																	setAttendance(
																		attendee.reservation_id,
																		false
																	);
																}}
															>
																<Close
																	color="error"
																	sx={{
																		my: "auto",
																	}}
																	fontSize="large"
																/>
															</IconButton>
															<IconButton
																aria-label=""
																onClick={() => {
																	setAttendance(
																		attendee.reservation_id,
																		true
																	);
																}}
															>
																<Check
																	color="success"
																	sx={{
																		my: "auto",
																	}}
																	fontSize="large"
																/>
															</IconButton>
														</Box>
													)
												) : (
													"Omluven"
												)}
											</Paper>
										)
									)}
							</Stack>
						</Stack>
					</Box>
					<Box sx={{ position: "relative" }}>
						{event_id !== "" && (
							<SpeedDial
								ariaLabel="SpeedDial basic example"
								sx={{
									position: "absolute",
									right: "15px",
									bottom: "15px",
								}}
								icon={<SpeedDialIcon />}
							>
								{actions.map((action) => (
									<SpeedDialAction
										key={action.name}
										icon={action.icon}
										tooltipTitle={action.name}
										onClick={action.action}
									/>
								))}
							</SpeedDial>
						)}
					</Box>
					<QrCodeScanDialog
						title="Naskenovat QR kód"
						open={openQrScan}
						setOpen={setOpenQrScan}
						event_id={event_id}
						attendeesQuery={attendeesQuery}
					/>
					<ConfirmEventNotHeldDialog
						open={openConfirmHeldDialog}
						setOpen={setOpenConfirmHeldDialog}
						action={handleEventIsHeld}
						event_id={event_id}
						held={held}
					/>
					<SnackBarHandler
						snackState={snackState}
						snackMsg={snackMsg}
						snackOpen={snackOpen}
						setSnackOpen={setSnackOpen}
					/>
				</>
			)}
		</>
	);
}
