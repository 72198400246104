import PropTypes from "prop-types"
import { gql, useMutation } from "@apollo/client";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import {
	ContentState,
	convertFromRaw,
	convertToRaw,
	EditorState,
} from "draft-js";
import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

import { ArticleFormFields } from "src/atoms";

const EDIT_ARTICLE = gql`
	mutation editArticle(
		$news_id: Int!
		$date: String
		$title: String!
		$article: String!
	) {
		editArticle(
			news_id: $news_id
			date: $date
			title: $title
			article: $article
		)
	}
`;
/**
 * Dialog to edit articles.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} refetch refetch new data from database
 * @param {Object} data selected article data
 * @returns {Component} Dialog
 */
export function EditNewsDialog({
	open,
	setOpen,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
	data,
	refetch,
}) {
	const [newArticleDate, setNewArticleDate] = useState(null);
	const [newArticleTitle, setNewArticleTitle] = useState("");
	const [newArticle, setNewArticle] = useState(() =>
		EditorState.createEmpty()
	);

	const initialValues = {
		newArticleDate: newArticleDate,
		newArticle: newArticle,
		newArticleTitle: newArticleTitle,
	};

	useEffect(() => {
		setNewArticle(
			EditorState.createWithContent(
				ContentState(convertFromRaw(JSON.parse(data.newsById.article)))
			)
		);
		setNewArticleTitle(data.newsById.title);
		setNewArticleDate(new Date(parseInt(data.newsById.timestamp)));
	}, [
		data.newsById.article,
		data.newsById.timestamp,
		data.newsById.title,
		refetch,
	]);

	const [addNewArticleRequest] = useMutation(EDIT_ARTICLE, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.editArticle);
			setSnackOpen(true);
			handleClose();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleEditArticleSubmit = useCallback(() => {
		addNewArticleRequest({
			variables: {
				news_id: parseInt(data.newsById.news_id),
				date: newArticleDate
					? `${newArticleDate.getFullYear()}-${
							newArticleDate.getMonth() + 1
					  }-${newArticleDate.getDate()}`
					: null,
				title: newArticleTitle,
				article: JSON.stringify(
					convertToRaw(newArticle.getCurrentContent())
				),
			},
		});
	}, [
		addNewArticleRequest,
		data.newsById.news_id,
		newArticle,
		newArticleDate,
		newArticleTitle,
	]);

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="md"
			fullWidth={true}
		>
			<Formik
				onSubmit={handleEditArticleSubmit}
				initialValues={initialValues}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<DialogTitle id="alert-dialog-title">
						{"Upravit článek"}
					</DialogTitle>
					<DialogContent>
						<ArticleFormFields
							setNewArticle={setNewArticle}
							setNewArticleTitle={setNewArticleTitle}
							newArticleTitle={newArticleTitle}
							setNewArticleDate={setNewArticleDate}
							newArticleDate={newArticleDate}
							newArticle={newArticle}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Uložit
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

EditNewsDialog.propTypes = {
	data: PropTypes.shape({
		editArticle: PropTypes.any,
		newsById: PropTypes.shape({
			article: PropTypes.any,
			news_id: PropTypes.any,
			timestamp: PropTypes.any,
			title: PropTypes.any
		})
	}),
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
