import { useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BallDanceContainer, CourseContainer } from "src/organisms";
import { useAuth } from "src/utils/auth";
import jwt_decode from "jwt-decode";

const COURSE_LIST = gql`
	query getCourses {
		courses {
			course_id
			name
			description
			cover
		}
	}
`;

const USER_INFO = gql`
	query user($user_id: Int!) {
		userById(user_id: $user_id) {
			user_id
			first_name
			last_name
			gender
			email
			street
			city
			zip_code
			house_number
			phone_number
			partners_name
			partners_email
		}
	}
`;

/**
 * Page with list of all courses. Some are ball dance, some are dance studio.
 * The First one has listed seasons to sign in and the latter one opens dialog to buy certain amount of entries to other list of seasons.
 * @returns {Component} Page with BallDance and Course Containers
 */
export function CoursesPage() {
	let { state } = useLocation();
	const { data, loading } = useQuery(COURSE_LIST);
	const { token } = useAuth();
	const decoded = token && jwt_decode(token);
	const [user_id] = useState(decoded?.user_id);
	const [gender, setGender] = useState();

	useEffect(() => {
		setTimeout(() => {
			if (state && state.ref !== null && !loading) {
				const pointToScroll = document.getElementById(state.ref);
				pointToScroll.scrollIntoView();
				window.history.replaceState({}, document.title);
			}
		},300);
	}, [data, loading, state]);

	const userInfoQuery = useQuery(USER_INFO, {
		variables: {
			user_id: parseInt(user_id),
		},
		skip: !token,
	});

	useEffect(() => {
		if (userInfoQuery.data) {
			setGender(userInfoQuery.data.userById.gender);
		}
	}, [userInfoQuery.data]);

	return (
		<Box>
			{data &&
				data.courses.map((course) => (
					<div key={course.course_id}>
						{course.course_id < 3 ? (
							<BallDanceContainer
								id={course.course_id}
								description={course.description}
								name={course.name}
								img={course.cover}
								courseId={course.course_id}
								key={course.course_id}
							/>
						) : (
							<CourseContainer
								gender={gender}
								id={course.course_id}
								description={course.description}
								name={course.name}
								img={course.cover}
								courseId={course.course_id}
								key={course.course_id}
							/>
						)}
					</div>
				))}
		</Box>
	);
}
