import {
	Button,
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { AdminPanel } from "src/templates";
import { useParams } from "react-router-dom";
import {
	ArrowBackIos,
	ArrowForwardIos,
	CheckCircleOutline,
	HighlightOff,
} from "@mui/icons-material";
import { convertFromRaw } from "draft-js";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { route } from "src/Routes";
import { useCallback } from "react";
import { EditSeasonDialog } from "../Dialogs/EditSeasonDialog";
import { NewSeasonDialog } from "../Dialogs/NewSeasonDialog";

const SEASONS_LIST = gql`
	query ballroomSeasonsByCourseId($course_id: Int!) {
		ballroomSeasonsByCourseId(course_id: $course_id) {
			season_id
			season
			start_date
			end_date
			day
			start_time
			end_time
			time
			capacity
			duration
			active
			min_participation
			price
		}
	}
`;

const COURSE_INFO = gql`
	query courseById($course_id: Int!) {
		courseById(course_id: $course_id) {
			course_id
			name
		}
	}
`;

const SWITCH_ACTIVE = gql`
	mutation switchActiveSeason($season_id: Int!, $active: Boolean!) {
		switchActiveSeason(season_id: $season_id, active: $active)
	}
`;

const DAY_MAP = {
	monday: "pondělí",
	tuesday: "úterý",
	wednesday: "středa",
	thursday: "čtvrtek",
	friday: "pátek",
	saturday: "sobota",
	sunday: "neděle",
};

/**
 * Admin section content with seasons of selected course.
 * Admin and lecturers can edit capacity, start/end date, active/hidden and other parameters of season, or
 * add a new season.
 * By clicking on active icon button, the state changes.
 * @returns {Component} Table with seasons of selected course 
 */
export function CourseSeasonsManagement() {
	const { course_id } = useParams();

	const [editSeasonDialogOpen, setEditSeasonDialogOpen] = useState(false);
	const [newSeasonDialogOpen, setNewSeasonDialogOpen] = useState(false);
	const [editSeasonData, setEditSeasonData] = useState({});
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const { data: seasonsData, refetch: refetchSeasons } = useQuery(
		SEASONS_LIST,
		{
			variables: {
				course_id: parseInt(course_id),
			},
		}
	);
	const { data: courseData } = useQuery(COURSE_INFO, {
		variables: {
			course_id: parseInt(course_id),
		},
	});
	const [activeRequest] = useMutation(SWITCH_ACTIVE, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.switchActiveSeason);
			setSnackOpen(true);
			refetchSeasons();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleActive = useCallback(
		(season_id, active) => {
			activeRequest({
				variables: {
					season_id: season_id,
					active: active,
				},
			});
		},
		[activeRequest]
	);

	return (
		<AdminPanel
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Box sx={{ display: "flex", flexDirection: "row", mb: "20px" }}>
				<IconButton
					sx={{ my: "auto" }}
					size="small"
					disableRipple={true}
					component={Link}
					href={route.courseManagement()}
				>
					<ArrowBackIos />
				</IconButton>
				{courseData && (
					<Typography variant="h2" color="initial" fontSize="28px">
						{convertFromRaw(
							JSON.parse(courseData.courseById.name)
						).getPlainText()}
					</Typography>
				)}
			</Box>
			<TableContainer sx={{ height: "100%" }}>
				<Button
					variant="contained"
					color="secondary"
					sx={{
						borderRadius: "5px",
						ml: "auto",
						mr: "10px",
						display: "flex",
					}}
					onClick={() => {
						setNewSeasonDialogOpen(true);
					}}
				>
					Nová sezóna
				</Button>
				<Table
					sx={{
						borderCollapse: "separate",
						overflow: "hidden",
						overflowY: "scroll",
						overflowX: "scroll",
					}}
					aria-label="simple table"
					size="small"
				>
					<TableHead>
						<TableRow>
							<TableCell>Sezóna</TableCell>
							<TableCell>Začátek sezóny</TableCell>
							<TableCell>Konec sezóny</TableCell>
							<TableCell>Den</TableCell>
							<TableCell align="center">Začátek lekce</TableCell>
							<TableCell align="center">Konec lekce</TableCell>
							<TableCell align="center">Kapacita</TableCell>
							<TableCell align="center">Doba trvání</TableCell>
							{course_id < 4 ? (
								<TableCell align="center">Aktivní</TableCell>
							) : (
								<></>
							)}
							<TableCell align="right"></TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{seasonsData &&
							seasonsData.ballroomSeasonsByCourseId !== null &&
							seasonsData.ballroomSeasonsByCourseId.map(
								(season, index) => (
									<TableRow key={index}>
										<TableCell align="left">
											{season.season}
										</TableCell>
										<TableCell align="left">
											{season.start_date}
										</TableCell>
										<TableCell align="left">
											{season.end_date}
										</TableCell>
										<TableCell align="left">
											{DAY_MAP[season.day]}
										</TableCell>
										<TableCell align="center">
											{season.start_time}
										</TableCell>
										<TableCell align="center">
											{season.end_time}
										</TableCell>
										<TableCell align="center">
											{season.capacity}
										</TableCell>
										<TableCell align="center">
											{season.duration}
										</TableCell>
										{course_id < 4 ? (
											<TableCell align="center">
												{season.active === false ? (
													<IconButton
														onClick={() => {
															handleActive(
																season.season_id,
																true
															);
														}}
													>
														<HighlightOff
															sx={{
																color: "response.error",
															}}
														/>
													</IconButton>
												) : (
													<IconButton
														onClick={() => {
															handleActive(
																season.season_id,
																false
															);
														}}
													>
														<CheckCircleOutline
															sx={{
																color: "response.success",
															}}
														/>
													</IconButton>
												)}
											</TableCell>
										) : (
											<></>
										)}
										<TableCell align="center">
											<Button
												variant="contained"
												size="small"
												onClick={() => {
													setEditSeasonDialogOpen(
														true
													);
													setEditSeasonData(season);
												}}
											>
												Upravit
											</Button>
										</TableCell>

										<TableCell align="center">
											<Button
												variant="contained"
												size="small"
												component={Link}
												endIcon={<ArrowForwardIos />}
												href={`/sprava-kurzu/${course_id}/${season.season_id}`}
											>
												Lekce
											</Button>
										</TableCell>
									</TableRow>
								)
							)}
					</TableBody>
				</Table>
			</TableContainer>
			<EditSeasonDialog
				open={editSeasonDialogOpen}
				setOpen={setEditSeasonDialogOpen}
				seasonData={editSeasonData}
				refetch={() => {
					refetchSeasons();
				}}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
				dayMap={DAY_MAP}
			/>
			<NewSeasonDialog
				open={newSeasonDialogOpen}
				setOpen={setNewSeasonDialogOpen}
				refetch={() => {
					refetchSeasons();
				}}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
				course_id={course_id}
				season_id={null}
			/>
		</AdminPanel>
	);
}
