import PropTypes from "prop-types"
import {
	Avatar,
	Badge,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import * as yup from "yup";
import {
	ContentState,
	convertFromRaw,
	convertToRaw,
	EditorState,
} from "draft-js";
import { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { CameraAlt } from "@mui/icons-material";
import { RichEditor } from "src/molecules";

const schema = yup.object().shape({
	role: yup.string().required("Role je povinný údaj"),
});

const CHANGE_LECTURER = gql`
	mutation updateLecturer(
		$user_id: Int!
		$role: String!
		$user_description: String
	) {
		updateLecturer(
			user_id: $user_id
			role: $role
			user_description: $user_description
		)
	}
`;

const UPLOAD_PHOTO = gql`
	mutation editProfilePicture($user_id: Int!, $photo: String!) {
		editProfilePicture(user_id: $user_id, photo: $photo)
	}
`;

/**
 * Dialog to edit lecturers role, picture or text.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} refetch refetch new data from database
 * @param {Object} lecturerData selected lecturer data
 * @returns {Component} Dialog
 */
export function EditLecturerDialog({
	open,
	setOpen,
	refetch,
	lecturerData,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
}) {
	const [role, setRole] = useState("");
	let filesBase64 = [];
	const [userDescription, setUserDescription] = useState(() =>
		EditorState.createEmpty()
	);

	useEffect(() => {
		if (lecturerData) {
			setRole(lecturerData.role);
			if (
				lecturerData.user_description !== null &&
				lecturerData.user_description !== undefined &&
				lecturerData.user_description !== ""
			) {
				setUserDescription(
					EditorState.createWithContent(
						ContentState(
							convertFromRaw(
								JSON.parse(lecturerData.user_description)
							)
						)
					)
				);
			}else{
        setUserDescription(
					EditorState.createEmpty()
        )
      }
		}
	}, [lecturerData]);

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	const handleRoleSelect = (selectedRole) => {
		setRole(selectedRole);
	};

	const [changeContentRequest] = useMutation(CHANGE_LECTURER, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.updateLecturer);
			setSnackOpen(true);
			handleClose();
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSubmit = useCallback(() => {
		changeContentRequest({
			variables: {
				user_id: parseInt(lecturerData.user_id),
				user_description: JSON.stringify(
					convertToRaw(userDescription.getCurrentContent())
				),
				role: role,
			},
		});
	}, [changeContentRequest, lecturerData.user_id, role, userDescription]);

	const [uploadImageRequest] = useMutation(UPLOAD_PHOTO, {
		onCompleted: async (data) => {
			setSnackState("success");
			setSnackMsg(data.editProfilePicture);
			setSnackOpen(true);
			await refetch();
			handleClose();
			setTimeout(() => {
				document
					.getElementById(
						`${lecturerData.user_id}_setLecturerDataBtn`
					)
					.click();
			}, 700);
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const uploadImage = useCallback(
		(photo) => {
			uploadImageRequest({
				variables: {
					user_id: lecturerData.user_id,
					photo: photo,
				},
			});
		},
		[lecturerData.user_id, uploadImageRequest]
	);

	const handleImageUpload = (e) => {
		let allfiles = [];

		for (let i = 0; i < e.target.files.length; i++) {
			if (e.target.files[i].size > 5e5) {
				setSnackState("error");
				setSnackMsg(
					"Prosím nahrajte obrázek s velikostí menší než 0,5 MB."
				);
				setSnackOpen(true);
				return false;
			}
			allfiles.push(e.target.files[i]);
		}

		getBase64(e.target.files[0]);
	};

	const getBase64 = async (file) => {
		return new Promise((resolve) => {
			let baseURL = "";
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				baseURL = reader.result;
				filesBase64.push(baseURL);
				resolve(baseURL);
			};
		}).then(async (res) => {
			uploadImage(res);
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth={true}
			transitionDuration={500}
		>
			<Formik
				onSubmit={handleSubmit}
				initialValues={{
					role: lecturerData.role,
					user_description: lecturerData.user_description,
				}}
				validationSchema={schema}
				validateOnBlur={true}
				enableReinitialize={true}
			>
				<Form>
					<Box sx={{ mx: "auto", width: "fit-content" }}>
						<Badge
							overlap="circular"
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							badgeContent={
								<IconButton
									disableFocusRipple={true}
									aria-label="Nahrát fotografii"
									sx={{
										color: "#242424",
										bgcolor: "#bdbdbd",
									}}
								>
									<label
										style={{
											width: "36px",
											height: "36px",
											cursor: "pointer",
										}}
										onChange={handleImageUpload}
										htmlFor="editImg"
									>
										<input
											accept="image/*"
											id="editImg"
											type="file"
											hidden
											style={{
												width: "0px",
												height: "0px",
											}}
										/>
										<CameraAlt fontSize="large" />
									</label>
								</IconButton>
							}
						>
							<Avatar
								sx={{
									mx: "auto",
									width: 164,
									height: 164,
									mt: "20px",
								}}
								src={lecturerData.photo}
							/>
						</Badge>
					</Box>

					<DialogContent sx={{ py: "5px" }}>
						{lecturerData && (
							<Typography
								variant="body1"
								fontSize="28px"
								color="initial"
								sx={{ mx: "auto", width: "fit-content" }}
							>
								{lecturerData.first_name}{" "}
								{lecturerData.last_name}
							</Typography>
						)}
						<Select
							sx={{ width: "100%", my: "15px" }}
							size="small"
							value={role}
							onChange={(e) => {
								handleRoleSelect(e.target.value);
							}}
						>
							<MenuItem value={"admin"}>Správce</MenuItem>
							<MenuItem value={"lecturer"}>Lektor</MenuItem>
							<MenuItem value={"user"}>Uživatel</MenuItem>
						</Select>
						<RichEditor
							id="user_description"
							name="user_description"
							label="Popis"
							type="text"
							editorState={userDescription}
							setEditorState={setUserDescription}
							toolbar={{
								options: [
									"inline",
									"blockType",
									"fontSize",
									"list",
									"textAlign",
									"link",
									"colorPicker",
									"emoji",
									"history",
								],
								inline: {
									superscript: false,
								},
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Uložit
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

EditLecturerDialog.propTypes = {
	lecturerData: PropTypes.shape({
		first_name: PropTypes.any,
		last_name: PropTypes.any,
		photo: PropTypes.any,
		role: PropTypes.any,
		user_description: PropTypes.string,
		user_id: PropTypes.any
	}),
	open: PropTypes.any,
	refetch: PropTypes.func,
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
