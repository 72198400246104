import { Button, IconButton, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { AdminPanel } from "src/templates";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
	ContentState,
	convertFromRaw,
	convertToRaw,
	EditorState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { RichEditor } from "src/molecules";
import * as yup from "yup";
import { Form, Formik } from "formik";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import { route } from "src/Routes";
import { ArrowBackIos } from "@mui/icons-material";

const COURSE_INFO = gql`
	query courseById($course_id: Int!) {
		courseById(course_id: $course_id) {
			course_id
			name
			description
			short_description
			cover
		}
	}
`;

const CHANGE_CONTENT = gql`
	mutation updateCourse(
		$course_id: Int!
		$name: String!
		$description: String!
		$short_description: String!
	) {
		updateCourse(
			course_id: $course_id
			name: $name
			description: $description
			short_description: $short_description
		)
	}
`;

const schema = yup.object().shape({});

/**
 * Admin section with selected course content to be edited.
 * Uses react-draft-wysiwyg to edit Rich (formatted) texts.
 * Text is displayed in Courses page.
 * @return {Component} Form with rich text editable web page content
 */
export function EditCourseContent() {
	const { course_id } = useParams();

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const [description, setDescription] = useState(() =>
		EditorState.createEmpty()
	);
	const [name, setName] = useState(() => EditorState.createEmpty());
	const [short_description, setShortDescription] = useState(() =>
		EditorState.createEmpty()
	);

	const { loading, data, refetch } = useQuery(COURSE_INFO, {
		variables: {
			course_id: parseInt(course_id),
		},
	});

	const initialValues = {
		course_id: parseInt(course_id),
		name: name,
		short_description: short_description,
		description: description,
	};

	useEffect(() => {
		if (!loading && data) {
			setDescription(
				EditorState.createWithContent(
					ContentState(
						convertFromRaw(JSON.parse(data.courseById.description))
					)
				)
			);
			setName(
				EditorState.createWithContent(
					ContentState(
						convertFromRaw(JSON.parse(data.courseById.name))
					)
				)
			);
			setShortDescription(
				EditorState.createWithContent(
					ContentState(
						convertFromRaw(
							JSON.parse(data.courseById.short_description)
						)
					)
				)
			);
		}
	}, [loading, data]);

	const [changeContentRequest] = useMutation(CHANGE_CONTENT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.updateCourse);
			setSnackOpen(true);
			refetch();
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleSubmit = useCallback(() => {
		changeContentRequest({
			variables: {
				course_id: parseInt(course_id),
				name: JSON.stringify(convertToRaw(name.getCurrentContent())),
				short_description: JSON.stringify(
					convertToRaw(short_description.getCurrentContent())
				),
				description: JSON.stringify(
					convertToRaw(description.getCurrentContent())
				),
			},
		});
	}, [changeContentRequest, course_id, description, name, short_description]);

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<AdminPanel
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<IconButton
					sx={{ my: "auto" }}
					size="small"
					disableRipple={true}
					component={Link}
					href={route.courseContentManagement()}
				>
					<ArrowBackIos />
				</IconButton>
				{data && (
					<Typography
						variant="h2"
						fontSize="1.5em"
						color="initial"
						dangerouslySetInnerHTML={createMarkup(
							draftToHtml(JSON.parse(data.courseById.name))
						)}
					></Typography>
				)}
			</Box>

			<Formik
				onSubmit={handleSubmit}
				initialValues={initialValues}
				validationSchema={schema}
				validateOnBlur={false}
				enableReinitialize={true}
			>
				<Form>
					<Typography
						variant="overline"
						fontWeight="bold"
						color="initial"
					>
						Název kurzu
					</Typography>
					<RichEditor
						id="description"
						name="description"
						label="Popis"
						type="text"
						editorState={name}
						setEditorState={setName}
						toolbar={{
							options: [
								"inline",
								"blockType",
								"fontSize",
								"list",
								"textAlign",
								"link",
								"colorPicker",
								"emoji",
								"history",
							],
							inline: {
								superscript: false,
							},
						}}
					/>
					<Typography
						variant="overline"
						fontWeight="bold"
						color="initial"
					>
						Krátký popis
					</Typography>
					<RichEditor
						id="short-description"
						name="short-description"
						label="Krátký popis"
						type="text"
						editorState={short_description}
						setEditorState={setShortDescription}
						toolbar={{
							options: [
								"inline",
								"blockType",
								"fontSize",
								"list",
								"textAlign",
								"link",
								"colorPicker",
								"emoji",
								"history",
							],
							inline: {
								superscript: false,
							},
						}}
					/>
					<Typography
						variant="overline"
						fontWeight="bold"
						color="initial"
					>
						Dlouhý popis
					</Typography>
					<RichEditor
						id="description"
						name="description"
						label="Dlouhý popis"
						type="text"
						editorState={description}
						setEditorState={setDescription}
						toolbar={{
							options: [
								"inline",
								"blockType",
								"fontSize",
								"list",
								"textAlign",
								"link",
								"colorPicker",
								"emoji",
								"history",
							],
							inline: {
								superscript: false,
							},
						}}
					/>
					<Button
						type="submit"
						variant="contained"
						sx={{
							mt: "5px",
							ml: "auto",
							mb: "15px",
							display: "flex",
						}}
					>
						Uložit
					</Button>
				</Form>
			</Formik>
		</AdminPanel>
	);
}
