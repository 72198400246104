import PropTypes from "prop-types"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, Typography } from "@mui/material";
import { QrReader } from "react-qr-reader";
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

const USER_INFO = gql`
	query user($user_id: Int!) {
		userById(user_id: $user_id) {
			user_id
			first_name
			last_name
			role
			email
		}
	}
`;

const QR_ATTENDANCE = gql`
	mutation setQRAttendance($user_id: Int!, $event_id: Int!) {
		setQrAttendance(user_id: $user_id, event_id: $event_id)
	}
`;

/**
 * Dialog for lecturer to scan students QR codes and confirm their attendance in doing so.
 * @param {String} title dialog title
 * @param {Function} setOpen open/close dialog
 * @param {Boolean} open opened/closed dialog
 * @param {Number} event_id event id
 * @param {Function} attendeesQuery query to fetch students
 * @returns {Component} Dialog
 */
export function QrCodeScanDialog({
	title,
	open,
	setOpen,
	event_id,
	attendeesQuery,
}) {
	const [qrData, setQrData] = useState();
	const [user_id, setUserId] = useState();

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setQrData();
			setUserId();
		}, 500);
	};

	let skip;
	if (user_id) {
		skip = false;
	} else {
		skip = true;
	}

	const getUserInfoQuery = useQuery(USER_INFO, {
		variables: {
			user_id: user_id,
		},
		skip: skip,
	});

	const [QrAttendanceRequest] = useMutation(QR_ATTENDANCE, {
		onCompleted: (data) => {
			attendeesQuery.refetch();
			handleClose();
		},
		onError: (error) => {},
	});

	const handleQrAttendance = useCallback(() => {
		QrAttendanceRequest({
			variables: {
				user_id: parseInt(user_id),
				event_id: event_id,
			},
		});
	}, [QrAttendanceRequest, event_id, user_id]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{ whiteSpace: "pre-line" }}
				>
					{title}
				</DialogTitle>
				{event_id !== "" && event_id !== null && event_id !== undefined ? (
					<DialogContent>
						{!qrData ? (
							<QrReader
								constraints={{ facingMode: "environment" }}
								onResult={(result, error) => {
									if (!!result) {
										setUserId(parseInt(result?.text));
										setQrData(result?.text);
										getUserInfoQuery.refetch();
									}
									if (!!error) {
									}
								}}
								style={{ width: "100%" }}
							/>
						) : (
							<>
								{getUserInfoQuery.data && (
									<>
										<Typography
											variant="body1"
											color="initial"
											fontSize="1.1em"
										>
											<strong>Naskenoval(a) jste uživatele:</strong>	
										</Typography>
										<Typography
											variant="body1"
											color="initial"
										>
											{
												getUserInfoQuery.data.userById
													.first_name
											}{" "}
											{
												getUserInfoQuery.data.userById
													.last_name
											}
										</Typography>
										<Typography
											variant="body1"
											color="initial"
										>
											{
												getUserInfoQuery.data.userById
													.email
											}
										</Typography>
									</>
								)}
							</>
						)}
					</DialogContent>
				) : (
					<DialogContent>
            <Typography variant="body1" color="initial">Nebyla vybrána žádná hodina.</Typography>
          </DialogContent>
				)}

				<DialogActions>
					{getUserInfoQuery.data && (
						<Button
							color="secondary"
							variant="contained"
							onClick={() => {
								handleQrAttendance();
							}}
							autoFocus
						>
							Potvrdit účast
						</Button>
					)}
					<Button
						component={Link}
						color="inherit"
						variant="text"
						onClick={() => {
							handleClose();
						}}
					>
						Zavřít
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

QrCodeScanDialog.propTypes = {
	attendeesQuery: PropTypes.shape({
		refetch: PropTypes.func
	}),
	event_id: PropTypes.string,
	open: PropTypes.any,
	setOpen: PropTypes.func,
	title: PropTypes.any
}
