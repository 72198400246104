import { AdminPanel } from "src/templates";
import { Box, Container, Button, Stack, Paper } from "@mui/material";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { useEffect } from "react";
import { arrayMoveImmutable } from "array-move";
import { DragIndicator } from "@mui/icons-material";
import { EditSponsorDialog } from "../Dialogs/EditSponsorDialog";
import { AddSponsorDialog } from "../Dialogs/AddSponsorDialog";

const SPONSORS_QUERY = gql`
	query sponsors {
		sponsors {
			sponsor_id
			name
			img
			position
			height
			link
		}
	}
`;

const SPONSORS_POSITION = gql`
	mutation setSponsorsPosition($sponsor_id: Int!, $position: Int!) {
		setSponsorsPosition(sponsor_id: $sponsor_id, position: $position)
	}
`;

/**
 * Admin section to create, edit or delete sponsors.
 * Sponsors are in special list from react-sortable-hoc library.
 * This library enables to easily order of items by drag&drop.
 * Every sponsor has logo, name and link. 
 * Also it has mandatory height in px, because we can't know in advance how the logo will look in the homepage.
 * @return {Component} SortableContainer => SortableItem
 */
export function SponsorsManagement() {
	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const { data, refetch: refetchSponsors } = useQuery(SPONSORS_QUERY);

	const [editSponsorDialog, setEditSponsorDialog] = useState(false);
	const [editSponsorData, setEditSponsorData] = useState({});

	const [addSponsorDialog, setAddSponsorDialog] = useState(false);

	const [sponsorsList, setSponsorsList] = useState([]);

	useEffect(() => {
		if (data) {
			setSponsorsList(data.sponsors);
		}
	}, [data]);

	const [changeSponsorsPositionRequest] = useMutation(SPONSORS_POSITION, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.setSponsorsPosition);
			setSnackOpen(true);
			refetchSponsors();
		},
		onError: (error) => {
			console.error(error);
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const SortableItem = sortableElement(({ value, sponsor }) => (
		<Paper
			sx={{
				height: "80px",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				cursor: "grab",
				px: "20px",
			}}
		>
			<DragIndicator />
			<Box
				sx={{
					justifyContent: "flex-start",
					display: "flex",
					flexDirection: "row",
					width: "100%",
					ml: "20px",
				}}
			>
				<img
					alt={sponsor.name}
					src={sponsor.img}
					height="60px"
					style={{
						userDrag: "none",
						WebkitUserDrag: "none",
						userSelect: "none",
						MozUserSelect: "none",
						WebkitUserSelect: "none",
						MsUserSelect: "none",
						pointerEvents: "none",
					}}
				/>
			</Box>
			<Button
				id={`${sponsor.sponsor_id}_sponsorBtn`}
				variant="contained"
				size="large"
				onClick={() => {
					setEditSponsorDialog(true);
					setEditSponsorData(sponsor);
				}}
			>
				Upravit
			</Button>
		</Paper>
	));

	const SortableContainer = sortableContainer(({ children }) => {
		return <Stack spacing={2}>{children}</Stack>;
	});

	const onSortEnd = ({ oldIndex, newIndex }) => {
		let newArray = arrayMoveImmutable(sponsorsList, oldIndex, newIndex);
		setSponsorsList(newArray);
		newArray.forEach((sponsor, index) => {
			if (sponsor.position !== index + 1) {
				changeSponsorsPositionRequest({
					variables: {
						sponsor_id: parseInt(sponsor.sponsor_id),
						position: parseInt(index + 1),
					},
				});
			}
		});
	};

	return (
		<AdminPanel
			title="Správa sponzorů"
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Container maxWidth="xl" sx={{ mt: "auto", mb: "30px", overflow: 'hidden', overflowY: 'scroll' }}>
				<Button
					variant="contained"
					color="secondary"
					sx={{
						borderRadius: "5px",
						ml: "auto",
						mb: "20px",
						display: "flex",
					}}
					onClick={() => {
						setAddSponsorDialog(true);
					}}
				>
					Přidat sponzora
				</Button>
				<SortableContainer onSortEnd={onSortEnd}>
					{sponsorsList &&
						sponsorsList.map((sponsor, index) => (
							<SortableItem
								key={sponsor.sponsor_id}
								index={index}
								value={sponsor.position}
								sponsor={sponsor}
							/>
						))}
				</SortableContainer>
			</Container>
			<EditSponsorDialog
				open={editSponsorDialog}
				setOpen={setEditSponsorDialog}
				sponsorData={editSponsorData}
				refetch={refetchSponsors}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
			/>
			<AddSponsorDialog
				open={addSponsorDialog}
				setOpen={setAddSponsorDialog}
				sponsorData={editSponsorData}
				refetch={refetchSponsors}
				setSnackMsg={setSnackMsg}
				setSnackState={setSnackState}
				setSnackOpen={setSnackOpen}
			/>
		</AdminPanel>
	);
}
