import PropTypes from "prop-types"
import { gql, useMutation } from "@apollo/client";
import { DatePicker, TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import cs from "date-fns/locale/cs";
import { Form, Formik } from "formik";
import { useCallback, useState, useEffect } from "react";
import * as yup from "yup";
import moment from "moment";
import { FormikField } from "src/molecules";

const EDIT_SEASON = gql`
	mutation editSeason(
		$season_id: Int!
		$season: String!
		$start_date: String!
		$end_date: String!
		$time: String!
		$day: String!
		$capacity: Int!
		$duration: Int!
		$min_participation: Int!
		$price: Int
	) {
		editSeason(
			season_id: $season_id
			season: $season
			start_date: $start_date
			end_date: $end_date
			time: $time
			day: $day
			capacity: $capacity
			duration: $duration
			min_participation: $min_participation
			price: $price
		)
	}
`;

const schema = yup.object().shape({
	season: yup.string().required("Název je povinný údaj"),
	duration: yup.number().required("Doba trvání je povinný údaj"),
	capacity: yup.number().required("Kapacita je povinný údaj"),
	min_participation: yup.number().required("Minimální účast je povinný údaj"),
	price: yup.number()
});

const DAY_MAP = [
	{ text: "pondělí", value: "monday" },
	{ text: "úterý", value: "tuesday" },
	{ text: "středa", value: "wednesday" },
	{ text: "čtvrtek", value: "thursday" },
	{ text: "pátek", value: "friday" },
	{ text: "sobota", value: "saturday" },
	{ text: "neděle", value: "sunday" },
];
/**
 * Dialog to edit seasons data like date, time, comment etc.
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Function} setSnackState function to set snack state Success, Warning, Error, Info
 * @param {Function} setSnackMsg function to set snack msg
 * @param {Function} setSnackOpen function to open/close informational snack message
 * @param {Function} refetch refetch new data from database
 * @param {Object} seasonData selected season data
 * @returns {Component} Dialog
 */
export function EditSeasonDialog({
	open,
	setOpen,
	setSnackState,
	setSnackMsg,
	setSnackOpen,
	refetch,
	seasonData
}) {
	const [start_date, setStartDate] = useState(new Date());
	const [end_date, setEndDate] = useState(new Date());
	const [time, setTime] = useState(new Date());
	const [day, setDay] = useState();

	useEffect(() => {
		const startDate = moment(seasonData.start_date, "DD. MM. YYYY hh:mm");
		setStartDate(startDate.toDate());
		const endDate = moment(seasonData.end_date, "DD. MM. YYYY hh:mm");
		setEndDate(endDate.toDate());
		const timeFormat = moment(seasonData.time, "hh:mm");
		setTime(timeFormat.toDate());
		setDay(seasonData.day)
	},[seasonData.day, seasonData.end_date, seasonData.start_date, seasonData.time])

	const [editSeasonRequest] = useMutation(EDIT_SEASON, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.editSeason);
			setSnackOpen(true);
			handleClose();
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleEditSeason = useCallback(
		(values) => {
			editSeasonRequest({
				variables: {
					season_id: seasonData.season_id,
					season: values.season,
					start_date: `${start_date.getFullYear()}-${(
						start_date.getMonth() + 1
					)
						.toString()
						.padStart(2, "0")}-${start_date
						.getDate()
						.toString()
						.padStart(2, "0")}`,
					end_date: `${end_date.getFullYear()}-${(
						end_date.getMonth() + 1
					)
						.toString()
						.padStart(2, "0")}-${end_date
						.getDate()
						.toString()
						.padStart(2, "0")}`,
					time: `${time.getHours().toString().padStart(2, "0")}:${time
						.getMinutes()
						.toString()
						.padStart(2, "0")}`,
					day: values.day,
					capacity: values.capacity,
					duration: values.duration,
					min_participation: values.min_participation,
					price: values.price
				},
			});
		},
		[editSeasonRequest, seasonData.season_id, start_date, end_date, time]
	);

	const handleClose = () => {
		setOpen(false);
		refetch();
	};

	const handleDaySelect = (selectedDay) => {
		setDay(selectedDay);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth={true}
		>
			<Formik
				onSubmit={handleEditSeason}
				initialValues={{
					season: seasonData.season,
					duration: seasonData.duration,
					capacity: seasonData.capacity,
					day: seasonData.day,
					min_participation: seasonData.min_participation,
					price: seasonData.price
				}}
				validationSchema={schema}
				validateOnBlur={true}
				enableReinitialize={true}
			>
				<Form>
					<DialogTitle id="alert-dialog-title">
						{"Upravit sezónu"}
					</DialogTitle>
					<DialogContent>
						<FormikField
							id="season"
							name="season"
							label="Název sezóny"
							type="text"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								locale={cs}
							>
								<DatePicker
									mask="__. __. ____ HH:mm"
									label="Začátek sezóny"
									value={start_date}
									onChange={(newValue) => {
										setStartDate(newValue);
									}}
									renderInput={(params) => (
										<TextField
											required
											color="secondary"
											size="small"
											sx={{
												mt: "15px",
												width: '49%'
											}}
											{...params}
										/>
									)}
								/>
							</LocalizationProvider>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								locale={cs}
							>
								<DatePicker
									mask="__. __. ____ HH:mm"
									label="Konec sezóny"
									value={end_date}
									onChange={(newValue) => {
										setEndDate(newValue);
									}}
									renderInput={(params) => (
										<TextField
											required
											color="secondary"
											size="small"
											sx={{
												mt: "15px",
												width: '49%'
											}}
											{...params}
										/>
									)}
								/>
							</LocalizationProvider>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								locale={cs}
							>
								<TimePicker
									mask="__. __. ____ HH:mm"
									label="Čas"
									value={time}
									onChange={(newValue) => {
										setTime(newValue);
									}}
									renderInput={(params) => (
										<TextField
											required
											color="secondary"
											size="small"
											sx={{
												mt: "15px",
												width: "49%"
											}}
											{...params}
										/>
									)}
								/>
							</LocalizationProvider>
							<Select
								name="day"
								id="day"
								sx={{ mt: "15px", width: "49%"}}
								size="small"
								value={day}
								onChange={(e) => {
									handleDaySelect(e.target.value);
								}}
								disabled
							>
								{DAY_MAP.map((day, index) => (
									<MenuItem key={index} value={day.value}>
										{day.text}
									</MenuItem>
								))}
							</Select>
						</Box>

						<FormikField
							id="duration"
							name="duration"
							label="Doba trvání (v minutách)"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<FormikField
							id="capacity"
							name="capacity"
							label="Kapacita"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<FormikField
							id="min_participation"
							name="min_participation"
							label="Minimální účast"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
						<FormikField
							id="price"
							name="price"
							label="Cena"
							type="number"
							variant="outlined"
							as="TextField"
							color="secondary"
							size="small"
							sx={{ mt: "15px" }}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							autoFocus
							type="submit"
						>
							Uložit
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

EditSeasonDialog.propTypes = {
	open: PropTypes.any,
	refetch: PropTypes.func,
	seasonData: PropTypes.shape({
		capacity: PropTypes.any,
		day: PropTypes.any,
		duration: PropTypes.any,
		end_date: PropTypes.any,
		min_participation: PropTypes.any,
		price: PropTypes.any,
		season: PropTypes.any,
		season_id: PropTypes.any,
		start_date: PropTypes.any,
		time: PropTypes.any
	}),
	setOpen: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func
}
