import PropTypes from "prop-types"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useCallback } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

/**
 * 
 * @param {String} title dialog title
 * @param {String} description dialog description
 * @param {Number} price price of the course
 * @param {String} bankAccount bank account to where should students send money
 * @param {Function} setOpen open/close dialog
 * @param {Boolean} open opened/closed dialog
 * @returns {Component} Dialog
 */
export function SuccessSignUpDialog({
	title,
	description,
	price,
	bankAccount,
	open,
	setOpen,
	refetch
}) {
	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{ whiteSpace: "pre-line", fontWeight: "bold" }}
				>
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						id="alert-dialog-description"
						sx={{ whiteSpace: "pre-line" }}
					>
						{description}
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<Box>
								<Typography
									sx={{
										fontWeight: "bold",
										fontSize: "1.2em",
									}}
								>
									{`Bankovní údaje`}
								</Typography>
								<Typography
									variant="body1"
									sx={{ fontSize: "1em" }}
								>
									{`Číslo bankovního účtu`}
								</Typography>
								<Typography
									variant="body1"
									sx={{ fontWeight: "bold", fontSize: "1em" }}
								>
									{bankAccount}
								</Typography>

								<Typography
									variant="body1"
									sx={{
										fontWeight: "bold",
										fontSize: "1.3em",
									}}
								></Typography>
							</Box>
						</Box>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Typography
						variant="body1"
						sx={{
							fontWeight: "bold",
							fontSize: "1.3em",
							mr: 'auto',
							ml: '10px'
						}}
					>
						{`Celková cena: ${price} Kč`}
					</Typography>
					<Button
						color="secondary"
						variant="contained"
						autoFocus
						onClick={() => {
							handleClose();
							if (!!refetch) {
								refetch();
							}
						}}
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

SuccessSignUpDialog.propTypes = {
	bankAccount: PropTypes.any,
	description: PropTypes.any,
	open: PropTypes.any,
	price: PropTypes.any,
	setOpen: PropTypes.func,
	title: PropTypes.any,
	refetch: PropTypes.any,
}
