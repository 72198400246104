import { AdminPanel } from "src/templates";
import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	IconButton,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

const COURSES_QUERY = gql`
	query courses {
		courses {
			course_id
			name
			description
			short_description
			cover
		}
	}
`;

/**
 * Admin section content with table of selectable courses to edit their seasons and events.
 * @returns {Component} Table with courses
 */
export function CourseManagement() {
	const { data } = useQuery(COURSES_QUERY);

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};

	return (
		<AdminPanel title="Správa kurzů" adminsOnly={true}>
			<Container maxWidth="xl" sx={{ mt: "auto", mb: "30px" }}>
				<TableContainer>
					<Table
						sx={{
							minWidth: 650,
							borderSpacing: "0 10px",
							borderCollapse: "separate",
							px: "10px",
						}}
						aria-label="simple table"
						size="small"
					>
						<TableBody>
							{data &&
								data.courses.map((row) => (
									<TableRow
										key={row.course_id}
										sx={{
											"td:first-child, th:first-child": {
												borderRadius: "15px 0 0 15px",
											},
											"td:last-child, th:last-child": {
												borderRadius: "0 15px 15px 0",
											},
											"td, th": {
												fontWeight: "600",
											},
											bgcolor: "primary.main",
											boxShadow:
												"4px 4px 4px 2px rgba(0, 0, 0, 0.25)",
											borderRadius: "15px",
										}}
									>
										<TableCell component="th" scope="row">
											<div
												dangerouslySetInnerHTML={createMarkup(
													draftToHtml(
														JSON.parse(row.name)
													)
												)}
											></div>
										</TableCell>
										<TableCell align="right">
											<IconButton
												href={`/sprava-kurzu/${row.course_id}`}
											>
												<ArrowForwardIos />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</AdminPanel>
	);
}
