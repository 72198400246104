import PropTypes from "prop-types"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, FormControlLabel, Link } from "@mui/material";
import { route } from "src/Routes";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useCallback } from "react";
import * as yup from "yup";

const schema = yup.object().shape({
	agreement: yup
		.bool()
		.oneOf(
			[true],
			"Pro registraci do kurzu musíte souhlasit s podmínkami Tanečního studia Ká."
		),
});

/**
 * Dialog to aggree with terms of attendance and operating rules
 * @param {String} title dialog title
 * @param {Function} setOpen open/close dialog
 * @param {Boolean} open opened/closed dialog
 * @param {String} description text description
 * @param {Function} action function called after submit. In this case to sign up for the course
 * @returns {Component} Dialog
 */
export function AgreementsDialog({
	title,
	description,
	open,
	setOpen,
	action,
}) {
	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	const handleSubmit = useCallback(() => {
		action();
		handleClose();
	}, [action, handleClose]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<Formik
				onSubmit={handleSubmit}
				initialValues={{
					agreement: false,
				}}
				validationSchema={schema}
				validateOnBlur={false}
			>
				<Form>
					<DialogTitle
						id="alert-dialog-title"
						sx={{ whiteSpace: "pre-line" }}
					>
						{title}
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							sx={{ whiteSpace: "pre-line" }}
						>
							{description}
						</DialogContentText>
						<Field
							as={FormControlLabel}
							type="checkbox"
							name="agreement"
							id="agreement"
							control={<Checkbox />}
							label={
								<div>
									<span>Souhlasím s </span>
									<Link
										sx={{
											color: "secondary.main",
											textDecoration: "none",
											fontWeight: "bold",
										}}
										target="_blank"
										href={route.podminkyUcasti()}
									>
										podmínkami účasti
									</Link>
									<span> a </span>
									<Link
										sx={{
											color: "secondary.main",
											textDecoration: "none",
											fontWeight: "bold",
										}}
										href={route.provozniRad()}
										target="_blank"
									>
										provozním řádem
									</Link>
									<span> Tanečního studia Ká </span>
								</div>
							}
						/>
						<ErrorMessage
							name="agreement"
							component="div"
							className="invalid-feedback"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="contained"
							type="submit"
							autoFocus
						>
							Zapsat se
						</Button>
						<Button onClick={handleClose} color="secondary">
							Zrušit
						</Button>
					</DialogActions>
				</Form>
			</Formik>
		</Dialog>
	);
}

AgreementsDialog.propTypes = {
	action: PropTypes.func,
	description: PropTypes.any,
	open: PropTypes.any,
	setOpen: PropTypes.func,
	title: PropTypes.any
}
