import PropTypes from "prop-types"
import {
  Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";

/**
 * Dialog to confirm the decision to delete an item.
 * @param {Number} id can be any id of the deleted item
 * @param {Function} deleteFunction function that deletes the selected item
 * @param {Boolean} open opened/closed dialog
 * @param {Function} setOpen open/close dialog
 * @param {Number} season_id season id
 * @param {Number} user_id user id
 * @returns {Component} Dialog
 */
export function DeleteDialog({ id, deleteFunction, open, setOpen, season_id, user_id }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Opravdu si přejete položku odstranit?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Potvrzením bude položka trvale smazána.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="error"
					variant="contained"
					onClick={() => {
						deleteFunction(id, user_id, season_id);
						handleClose();
					}}
					autoFocus
				>
					Odstranit
				</Button>
				<Button onClick={handleClose} color="secondary">
					Zrušit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

DeleteDialog.propTypes = {
	deleteFunction: PropTypes.func,
	id: PropTypes.any,
	open: PropTypes.any,
	season_id: PropTypes.any,
	setOpen: PropTypes.func,
	user_id: PropTypes.any
}
