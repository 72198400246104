import PropTypes from "prop-types"
import { useMutation } from "@apollo/client";
import {
	Block,
	CheckCircleOutline,
	HelpOutline,
	HighlightOff,
	Login,
	MeetingRoom,
} from "@mui/icons-material";
import {
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
	Tooltip,
	useMediaQuery,
} from "@mui/material";
import { useCallback } from "react";
import gql from "graphql-tag";

const SIGN_IN = gql`
	mutation signInBall($reservation_id: Int!) {
		signIntoBallroomReservation(reservation_id: $reservation_id)
	}
`;
const SIGN_OUT = gql`
	mutation signInBall($reservation_id: Int!) {
		signOutOfBallroomReservation(reservation_id: $reservation_id)
	}
`;

const CREATE_RESERVATION = gql`
	mutation createReservation($user_id: Int!, $event_id: Int!) {
		createReservation(user_id: $user_id, event_id: $event_id) {
			reservation_id
			event_id
			user_id
		}
	}
`;

const DAY_MAP = [
	"neděle",
	"pondělí",
	"úterý",
	"středa",
	"čtvrtek",
	"pátek",
	"sobota",
];

/**
 * Component to show user all his/hers events in signed up season. 
 * Enables him to excuse himself from the classes, see how many free entries he/she can use and when is he/she supposed to attend his/hers classes.
 * Also sees notes from lecturers about the event.
 * @param {Object} query events data
 * @param {Function} setSnackState snack message state (error, success,...) setter
 * @param {Function} setSnackOpen open/close setter function
 * @param {Function} setSnackMsg snack message setter function
 * @param {Function} refetch function to refetch table data
 * @param {Number} signOutsNumber number of users excused classes to count his free entries
 * @param {String} freeEntriesQueryName helper string to fetch correct data (ballroom or dance studio) about his/hers free entries
 * @param {String} queryName helper string to fetch correct data (ballroom or dance studio) of the events in season
 * @param {Number} user_id user id
 * @param {String} capacityCounterName helper string to fetch correct data (ballroom or dance studio) about the capacity of course
 * @returns {Component} Table
 */
export function UsersSeasonEventsTable({
	query,
	setSnackState,
	setSnackOpen,
	setSnackMsg,
	refetch,
	signOutsNumber,
	freeEntriesQueryName,
	queryName,
	user_id,
	capacityCounterName
}) {
	const tablet = useMediaQuery("(max-width:1560px)");
	const mobile = useMediaQuery("(max-width:600px)");
	const [signOutRequest] = useMutation(SIGN_OUT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.signOutOfBallroomReservation);
			setSnackOpen(true);
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error);
			setSnackOpen(true);
		},
	});
	const [signInRequest] = useMutation(SIGN_IN, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.signIntoBallroomReservation);
			setSnackOpen(true);
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error);
			setSnackOpen(true);
		},
	});

	const [createReservation] = useMutation(CREATE_RESERVATION, {
		onCompleted: () => {
			setSnackState("success");
			setSnackMsg("Kurz úspěšně rezervován");
			setSnackOpen(true);
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleExcuse = useCallback(
		(reservation_id, signed_out, event_id) => {
			if (signed_out === false) {
				signOutRequest({
					variables: {
						reservation_id: parseInt(reservation_id),
					},
				});
			} else {
				if (reservation_id !== null) {
					signInRequest({
						variables: {
							reservation_id: parseInt(reservation_id),
						},
					});
				} else {
					createReservation({
						variables: {
							user_id: parseInt(user_id),
							event_id: parseInt(event_id),
						},
					});
				}
			}
		},
		[createReservation, signInRequest, signOutRequest, user_id]
	);

	return (
		<TableContainer>
			<Table aria-label="simple table" size="small">
				<TableHead>
					<TableRow>
						<TableCell>Datum</TableCell>
						<TableCell align="center">Začátek</TableCell>
						<TableCell
							align="center"
							sx={{ display: mobile ? "none" : "table-cell" }}
						>
							Konec
						</TableCell>
						<TableCell align="center">Zapsán</TableCell>
						<TableCell
							align="center"
							sx={{ display: mobile ? "none" : "table-cell" }}
						>
							Docházka
						</TableCell>
						{queryName === "usersReservationsBySeasonId" ? (
							<>
								<TableCell
									align="center"
									sx={{ display: mobile ? "none" : "table-cell" }}
								>
									Ženy
								</TableCell>
								<TableCell
									align="center"
									sx={{ display: mobile ? "none" : "table-cell" }}
								>
									Muži
								</TableCell>
							</>
						) : (
							<TableCell
								align="center"
								sx={{ display: mobile ? "none" : "table-cell" }}
							>
								Kapacita
							</TableCell>
						)}
						<TableCell
							align="left"
							sx={{ display: mobile ? "none" : "table-cell" }}
						>
							Poznámka
						</TableCell>
						<TableCell padding="none"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{query &&
						query.map((event, index) => (
							<TableRow key={index}>
								<TableCell
									align="left"
									sx={{
										whiteSpace: "nowrap",
										overflow: "hidden",
									}}
								>
									{
										DAY_MAP[
										new Date(
											parseInt(event.event_date)
										).getUTCDay()
										]
									}{" "}
									{event.event_start.match(
										/\d{0,2}\.\s\d{0,2}\.\s\d{0,4}/
									)}
								</TableCell>
								<TableCell align="center">
									{
										event.event_start.match(
											/\s(\d{0,2}:\d{0,2})/
										)[0]
									}
								</TableCell>
								<TableCell
									align="center"
									sx={{ display: mobile ? "none" : "table-cell" }}
								>
									{
										event.event_end.match(
											/\s(\d{0,2}:\d{0,2})/
										)[0]
									}
								</TableCell>
								<TableCell align="center">
									{event.signed_out === true ? (
										<HighlightOff
											sx={{ color: "response.error" }}
										/>
									) : event.signed_out === false ? (
										<CheckCircleOutline
											sx={{
												color: "response.success",
											}}
										/>
									) : (
										<HelpOutline />
									)}
								</TableCell>
								<TableCell
									align="center"
									sx={{ display: mobile ? "none" : "table-cell" }}
								>
									{event.attended === false ? (
										<HighlightOff
											sx={{ color: "response.error" }}
										/>
									) : event.attended === true ? (
										<CheckCircleOutline
											sx={{
												color: "response.success",
											}}
										/>
									) : (
										<HelpOutline sx={{ color: "#e69602" }} />
									)}
								</TableCell>
								{queryName === "usersReservationsBySeasonId" ? (
									<>
										<TableCell
											align="center"
											sx={{
												display: mobile
													? "none"
													: "table-cell",
											}}
										>
											{event.women_count}/{event.capacity}
										</TableCell>
										<TableCell
											align="center"
											sx={{
												display: mobile
													? "none"
													: "table-cell",
											}}
										>
											{event.men_count}/{event.capacity}
										</TableCell>
									</>
								) : (
									<TableCell
										align="center"
										sx={{
											display: mobile ? "none" : "table-cell",
										}}
									>
										{event.reservation_count}/{event.capacity}
									</TableCell>
								)}

								<TableCell
									align="left"
									sx={{ display: mobile ? "none" : "table-cell" }}
								>
									{event.comment}
								</TableCell>
								<TableCell align="right" padding="none">
									{signOutsNumber && (
										<Tooltip
											title={
												event.signed_out === false
													? "Omluvit se z hodiny"
													: signOutsNumber[
														freeEntriesQueryName
													] > 0
														? "Zapsat se na hodinu"
														: "Žádné volné vstupy"
											}
										>
											<span>
												{tablet ? (
													<IconButton
														variant="contained"
														color="secondary"
														size="small"
														onClick={() => {
															handleExcuse(
																event.reservation_id,
																event.signed_out,
																event.event_id
															);
														}}
														disabled={
															event.held === true
																? event.signed_out !==
																	false
																	? signOutsNumber[
																		freeEntriesQueryName
																	] > 0
																		? event.capacity -
																			event[capacityCounterName] >
																			0
																			? false
																			: true
																		: true
																	: event.isIn7Hours ===
																		true
																		? true
																		: false
																: true
														}
													>
														{event.signed_out ===
															false ? (
															<MeetingRoom />
														) : signOutsNumber[
															freeEntriesQueryName
														] > 0 ? (
															<Login />
														) : (
															<Block />
														)}
													</IconButton>
												) : (
													<Button
														variant="contained"
														color="secondary"
														size="small"
														onClick={() => {
															handleExcuse(
																event.reservation_id,
																event.signed_out,
																event.event_id
															);
														}}
														disabled={
															event.held === true
																? event.signed_out !==
																	false
																	? signOutsNumber[
																		freeEntriesQueryName
																	] > 0
																		? event.capacity -
																			event[capacityCounterName] >
																			0
																			? false
																			: true
																		: true
																	: event.isIn7Hours ===
																		true
																		? true
																		: false
																: true
														}
													>
														{event.signed_out === false
															? "Omluvit se"
															: signOutsNumber[
																freeEntriesQueryName
															] > 0
																? "Zapsat se"
																: "Žádné volné vstupy"}
													</Button>
												)}
											</span>
										</Tooltip>
									)}
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

UsersSeasonEventsTable.propTypes = {
	capacityCounterName: PropTypes.any,
	freeEntriesQueryName: PropTypes.any,
	query: PropTypes.shape({
		map: PropTypes.func
	}),
	queryName: PropTypes.string,
	refetch: PropTypes.func,
	setSnackMsg: PropTypes.func,
	setSnackOpen: PropTypes.func,
	setSnackState: PropTypes.func,
	signOutsNumber: PropTypes.any,
	user_id: PropTypes.any
}
